import React from 'react';
import { useAppDispatch, useAppSelector } from '../stores/store';
import { openLoginModal } from '../features/auth/authSlice';
import { Button, Typography, Box } from '@mui/material';
import useActivity from "../hooks/useActivity";
import {showNotification} from "../features/notification/notificationSlice";

const Campaign: React.FC = () => {
    const dispatch = useAppDispatch();
    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
    const { registerActivity } = useActivity();
    const handleButtonClick = () => {
        if (!isAuthenticated) {
            dispatch(openLoginModal());
        } else {
            // Acțiuni când utilizatorul este autentificat
            console.log('User is authenticated, perform action.');
            registerActivity({
                name:'Campanie',
                payload:{id: 15, status:'merge'},
                callback: () => {
                    console.log('merge mintea la baiatu');
                    dispatch(showNotification({message:'merge mintea la baiatu',variant:'success'}))
                }
            });
        }
    };

    return (
        <Box sx={{ padding: 2, border: '1px solid #ddd', borderRadius: 4, textAlign: 'center' }}>
            <Typography variant="h4" component="h2" gutterBottom>
                Join Our Campaign!
            </Typography>
            <Box
                component="img"
                src="https://via.placeholder.com/150"
                alt="Campaign"
                sx={{ width: '100%', height: 'auto', maxWidth: 400, margin: 'auto', display: 'block' }}
            />
            <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: 2 }}
                onClick={handleButtonClick}
            >
                Join Now
            </Button>
        </Box>
    );
};

export default Campaign;
