import apiInstance from '../api/api';
import {useAppDispatch} from '../stores/store';
import {showNotification} from "../features/notification/notificationSlice";
import {getCoins} from "../features/profile/profileSlice";

interface UseActivityParams {
    name: string;
    payload: object;
    callback?: (response: any) => void;
}

const useActivity = () => {
    const dispatch = useAppDispatch();
    const registerActivity = ({name, payload, callback}: UseActivityParams) => {
        apiInstance.post('/activity', {
            name,
            ...payload,
        }).then(response => {
            if(response.data?.hasCoinsChange){
                dispatch(getCoins());
            }
            if (callback) {
                callback(response.data);
            }
        }).catch(err => {
            dispatch(showNotification({
                message: err?.response?.data?.message || err.message || 'An error occurred',
                variant: 'error'
            }))
        }).finally(() => {
            console.log('registerActivity finally')
        })
    };

    return {registerActivity};
};

export default useActivity;
