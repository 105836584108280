import ReactGA from 'react-ga4';

export const initGA = () => {
    const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_UA;

    ReactGA.initialize(gaId || 'UA-XXXXXXX-X');
};

export const logPageView = () => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};
