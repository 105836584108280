import React, {useRef, useState} from 'react';
import useVideoPlayerStyles from "../styles/videoPlayerStyles";
import play_button from '../assets/images/play_button.png';

interface VideoPlayerProps {
    src: string;
    type: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({src, type}) => {
    const videoPlayerStyles = useVideoPlayerStyles();
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <div className={videoPlayerStyles.container}>
            <video
                ref={videoRef}
                controls
                className={videoPlayerStyles.video}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
            >
                <source src={src} type={type}/>
            </video>
        </div>
    );
};

export default VideoPlayer;
