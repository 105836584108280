import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';
import bgWoodLight from '../../assets/images/patternLemn.jpg';
import patternAlb from '../../assets/images/patternAlb.jpg';

const useFormNcpStyles = createUseStyles((theme: Theme) => {
    return {
        boxTitle: {
            backgroundImage: `url(${bgWoodLight})`,
            backgroundRepeat: 'repeat',
            backgroundPosition: 'cover',
            objectFit: 'cover',
            padding: theme.spacing(5),
            paddingBottom: theme.spacing(10),
            borderRadius: '7vh',
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(5),
                paddingBottom: theme.spacing(12),
                borderRadius: '5vh',
            }
        },
        title: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(5vh,5vw)',
            fontWeight: "bold",
            [theme.breakpoints.down('sm')]: {
                fontSize: 'max(3vh,3vw)',
                fontWeight: "normal",
                letterSpacing: '1px',
            }
        },
        paperForm: {
            borderRadius: '3vw',
            margin: theme.spacing(10),
            marginTop: `-${theme.spacing(10)}`,
            padding: theme.spacing(10),
            backgroundImage: `url(${patternAlb})`,
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(4),
                marginTop: `-${theme.spacing(10)}`,
                padding: theme.spacing(3),
            },
        },
        formInput:{
            marginTop: theme.spacing(3),
            '& .MuiInputBase-root': {
                fontFamily: theme.fonts.apex,
                fontSize: '24px',
                lineHeight: 1,
                fontWeight: 'bold',
                borderRadius: theme.spacing(2),
                letterSpacing: '1px',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-root': {
                fontFamily: theme.fonts.apex,
                fontWeight: 'bold',
                fontSize: '24px',
                letterSpacing: '1px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-shrink': {
                fontSize: '24px',
                letterSpacing: '1px',
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            }
        },
        formControlCheckbox:{
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            fontSize: '24px',
            letterSpacing: '1px',
            marginTop: theme.spacing(3),
            display: 'flex',
            justifyContent: 'space-between',
            '& p': {
                textAlign: "left",
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
                fontWeight: 'normal',
            },
        },
        formControlCheckboxLabel:{
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            fontSize: '24px',
            letterSpacing: '1px',
            color: theme.palette.primary.main,
            '& a':{
                color: 'inherit',
                textDecoration: 'underline',
                textTransform: 'uppercase',
                fontStyle: 'italic',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                fontWeight: 'normal',
                lineHeight: 1,
            },
        },
        submitButton:{
            fontFamily: theme.fonts.apex,
            fontSize: '24px',
            fontWeight: 'bold',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            marginTop: theme.spacing(3),
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.main,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
                fontWeight: 'bold',
                marginTop: theme.spacing(3),
                padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
            },
            [theme.breakpoints.only('xs')]: {
                fontSize: '14px',
                padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
            }
        },
        mesajResponsabilitate: {
            maxWidth: '400px',
            width: '100%',
        },
        textKeepReceipt:{
            fontFamily: theme.fonts.apex,
            fontSize: '16px',
            color: theme.palette.secondary.main,
            marginTop: theme.spacing(3),
        }
    };
});

export default useFormNcpStyles;