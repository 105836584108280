import React from 'react';
import {Grid, Typography} from "@mui/material";
import useOurTraditionStyles from "../styles/our_tradition";
import clsx from "clsx";

const OurTradition: React.FC = () => {
    const ourTraditionStyles = useOurTraditionStyles();
    return(
      <Grid container>
          <Grid item xs={12} className={ourTraditionStyles.section}>
              <Typography className={ourTraditionStyles.title}>TRADIȚIA NOASTRĂ</Typography>
          </Grid>
          <Grid item xs={12} className={ourTraditionStyles.section}>
              <Grid container>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.bgImgOurTradition1}></Grid>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.subSectionRight}>
                      <Typography className={clsx(ourTraditionStyles.sectionTitle, 'contrast')}>1847</Typography>
                      <Typography className={clsx(ourTraditionStyles.sectionSubtitle, 'contrast')}>Primul nostru lot</Typography>
                      <Typography className={clsx(ourTraditionStyles.description, 'contrast')}>Primul nostru lot de Kozel este o bere brună produsă într-un cazan de 60 de hectolitri pe 15 decembrie 1874 în mica noastră fabrică din Velké Popovice.</Typography>
                  </Grid>
              </Grid>
          </Grid>
          <Grid item xs={12} className={ourTraditionStyles.section}>
              <Grid container>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.subSectionLeft}>
                      <Typography className={clsx(ourTraditionStyles.sectionTitle)}>1919</Typography>
                      <Typography className={clsx(ourTraditionStyles.sectionSubtitle)}>Legenda pictorului
                          francez rătăcit</Typography>
                      <Typography className={clsx(ourTraditionStyles.description)}>Un pictor francez care se rătăcise a fost ajutat de către locuitorii din Velké Popovice cu mâncare și adăpost. Drept mulțumire, le-a dăruit o pictură cu un țap.</Typography>
                  </Grid>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.bgImgOurTradition2}></Grid>
              </Grid>
          </Grid>
          <Grid item xs={12} className={ourTraditionStyles.section}>
              <Grid container>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.bgImgOurTradition3}></Grid>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.subSectionRight}>
                      <Typography className={clsx(ourTraditionStyles.sectionTitle, 'contrast')}>1930</Typography>
                      <Typography className={clsx(ourTraditionStyles.sectionSubtitle, 'contrast')}>Prima noastră
                          mascotă</Typography>
                      <Typography className={clsx(ourTraditionStyles.description, 'contrast')}>Primul nostru țap, păscând în parcul berăriei.</Typography>
                  </Grid>
              </Grid>
          </Grid>
          <Grid item xs={12} className={ourTraditionStyles.section}>
              <Grid container>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.subSectionLeft}>
                      <Typography className={clsx(ourTraditionStyles.sectionTitle)}>1970</Typography>
                      <Typography className={clsx(ourTraditionStyles.sectionSubtitle)}>Tradiția țapilor de la fabrică</Typography>
                      <Typography className={clsx(ourTraditionStyles.description)}>Încă din anii 70, avem câte un țap ca simbol al berăriei noastre. Iar țapul actual este Kozel al 15-lea!</Typography>
                  </Grid>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.bgImgOurTradition4}></Grid>
              </Grid>
          </Grid>
          <Grid item xs={12} className={ourTraditionStyles.section}>
              <Grid container>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.bgImgOurTradition5}></Grid>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.subSectionRight}>
                      <Typography className={clsx(ourTraditionStyles.sectionTitle, 'contrast')}>2009</Typography>
                      <Typography className={clsx(ourTraditionStyles.sectionSubtitle, 'contrast')}>Țapul Kozel, în loc de halbă</Typography>
                      <Typography className={clsx(ourTraditionStyles.description, 'contrast')}>Artistul ceh Jan Čapek a creat o nouă halbă cu un mâner special - inspirat de ceva ce orice fan adevărat al berii Kozel poate recunoaște: coarnele țapului.</Typography>
                  </Grid>
              </Grid>
          </Grid>
          <Grid item xs={12} className={ourTraditionStyles.section}>
              <Grid container>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.subSectionLeft}>
                      <Typography className={clsx(ourTraditionStyles.sectionTitle)}>2011</Typography>
                      <Typography className={clsx(ourTraditionStyles.sectionSubtitle)}>Vojtěch Homolka devine maestrul nostru berar</Typography>
                      <Typography className={clsx(ourTraditionStyles.description)}>El este responsabil de protejarea echilibrului dulce - amărui, care face Kozel cea mai țapănă bere cehă din lume.</Typography>
                  </Grid>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.bgImgOurTradition6}></Grid>
              </Grid>
          </Grid>
          <Grid item xs={12} className={ourTraditionStyles.section}>
              <Grid container>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.bgImgOurTradition7}>2011</Grid>
                  <Grid item sm={12} md={6}  className={ourTraditionStyles.subSectionRight}>
                      <Typography className={clsx(ourTraditionStyles.sectionTitle, 'contrast')}>2017</Typography>
                      <Typography className={clsx(ourTraditionStyles.sectionSubtitle, 'contrast')}>Primul nostru ritual de servire</Typography>
                      <Typography className={clsx(ourTraditionStyles.description, 'contrast')}>Coreea de Sud a inventat primul ritual de servire pentru berea dark, presărând scorțișoară deasupra. Asta a inspirat ritualul Cinnamon Art.</Typography>
                  </Grid>
              </Grid>
          </Grid>
      </Grid>
    );
}

export default OurTradition;