import React from "react";
import {Button, Grid, Typography} from "@mui/material";
import useTeaserTeamAndGame from "../../styles/kozelMeet/teaserTeamAndGame";
import clsx from "clsx";
import createGroup from '../../assets/images/kozel_meet/createGroup.gif';
import logo_game from '../../assets/images/kozel_meet/logo_game.png';
import ImageComponent from "../../utils/ImageComponent";
import {NavLink} from "react-router-dom";



const TeaserTeamAndGame: React.FC = () =>{
    const teaserTeamAndGame = useTeaserTeamAndGame();
    return (
        <Grid container item xs={12} className={teaserTeamAndGame.wrapperContainer}>
            <Grid container className={teaserTeamAndGame.container}>
                <Grid item xs={12} className={teaserTeamAndGame.containerItem}>
                    <Grid container className={teaserTeamAndGame.sectionContainer}>
                        <Grid item lg={4} xs={12} className={clsx(teaserTeamAndGame.sectionContainerItem, teaserTeamAndGame.sectionContainerItemPaddingRight)}>
                            <ImageComponent src={createGroup} alt={'createGroup'} />
                        </Grid>
                        <Grid item lg={8} xs={12} className={teaserTeamAndGame.sectionContainerItem}>
                            <Typography  className={teaserTeamAndGame.sectionText}>Invită-ți prietenii în comunitatea Kozel și împărtășiți experiențe, provocări, premii și berea ȚAPănă din Cehia!</Typography>
                            <NavLink to={'/'} className={teaserTeamAndGame.sectionButton}>{'ÎMI CREEZ ECHIPA'}</NavLink>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={clsx(teaserTeamAndGame.containerItem, teaserTeamAndGame.containerItemSeparator)}>
                    <Grid container className={teaserTeamAndGame.sectionContainer}>
                        <Grid item lg={8} xs={12} className={clsx(teaserTeamAndGame.sectionContainerItem, teaserTeamAndGame.sectionContainerItemPaddingRight)}>
                            <Typography className={teaserTeamAndGame.sectionText}>Accesează prima provocare Kozel Meeet, distrează-te împreună cu echipa ta și hai la UNTOLD să câștigați premii ȚAPene.</Typography>
                            <Typography className={teaserTeamAndGame.sectionTextBig}>Învârte sticla și acceptă provocarea!</Typography>
                            <Button className={teaserTeamAndGame.sectionButton}>START PROVOCARE</Button>
                        </Grid>
                        <Grid item lg={4} xs={12} className={teaserTeamAndGame.sectionContainerItem}>
                            <ImageComponent src={logo_game} alt={'logo_game'} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TeaserTeamAndGame;