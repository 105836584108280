// notificationManager.ts
import { SnackbarMessage, OptionsObject, enqueueSnackbar as enqueue, closeSnackbar as close } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import {Button} from "@mui/material";

let enqueueSnackbarRef: typeof enqueue | null = null;
let closeSnackbarRef: typeof close | null = null;

export const setSnackbar = (enqueueSnackbar: typeof enqueue, closeSnackbar: typeof close) => {
    enqueueSnackbarRef = enqueueSnackbar;
    closeSnackbarRef = closeSnackbar;
};

export const enqueueSnackbar = (message: SnackbarMessage, options?: OptionsObject) => {
    if (enqueueSnackbarRef) {
        enqueueSnackbarRef(message, {
            ...options,
            action: (key) => (
                <Button onClick={() => closeSnackbar(key)} style={{ color: 'white' }} variant={'text'}>
                    <CloseIcon />
                </Button>
    ),
    });
    }
};

export const closeSnackbar = (key: string | number) => {
    if (closeSnackbarRef) {
        closeSnackbarRef(key);
    }
};
