import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useListInfoStyles = createUseStyles((theme: Theme) => {
    return {
        contentTeaserListItemIcon: {
            width: 'max(3vh,3vw)',
            height: 'max(3vh,3vw)',
            marginRight: theme.spacing(3),
        },
        contentTeaserList: {
            fontFamily: theme.fonts.poplar,
        },
        contentTeaserListItem: {
            alignItems: 'center',
            padding: 0,
            '& .MuiListItemText-root': {
                '& span': {
                    fontFamily: theme.fonts.apex,
                    fontSize: 'max(1.5vh,1.5vw)',
                    color: theme.palette.primary.main,
                    margin: 0,
                    [theme.breakpoints.down('lg')]: {
                        fontSize: 'max(1.5vh,1.5vw)',
                    },
                }
            },
            '& .MuiListItemIcon-root': {
                [theme.breakpoints.down('lg')]: {
                    minWidth: '15px',
                },
                '& img': {
                    width: '15px',
                    height: 'auto',
                    marginTop: '4px',
                    marginBottom: '4px',
                    paddingRight: '4px',
                    [theme.breakpoints.down('lg')]: {
                        width: '10px',
                    },
                }
            }
        },
    }
})

export default useListInfoStyles;