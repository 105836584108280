import {createUseStyles} from 'react-jss';
import {Theme} from './themes/default';

const useLoginModalStyles = createUseStyles((theme: Theme) => {
    return {
        box:{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: theme.palette.background.default,
            width: 'min(60vw,500px)',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            padding: theme.spacing(5),
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vw,3vh)',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            marginTop: theme.spacing(5),
            textAlign: 'center',
        },
        formInput: {
            marginTop: theme.spacing(3),
            '& .MuiInputBase-root': {
                fontFamily: theme.fonts.apex,
                fontSize: '24px',
                lineHeight: 1,
                fontWeight: 'bold',
                borderRadius: theme.spacing(2),
                letterSpacing: '1px',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-root': {
                fontFamily: theme.fonts.apex,
                fontWeight: 'bold',
                fontSize: '24px',
                letterSpacing: '1px',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            },
            '& .MuiInputLabel-shrink': {
                fontSize: '24px',
                letterSpacing: '1px',
                color: theme.palette.primary.main,
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                },
            }
        },
        formControlCheckbox: {
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            fontSize: '24px',
            letterSpacing: '1px',
            marginTop: theme.spacing(3),
            display: 'flex',
            justifyContent: 'space-between',
            '& p': {
                textAlign: "left",
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
                fontWeight: 'normal',
            },
        },
        formControlCheckboxLabel: {
            fontFamily: theme.fonts.apex,
            fontWeight: 'bold',
            fontSize: '24px',
            letterSpacing: '1px',
            color: theme.palette.primary.main,
            '& a': {
                color: 'inherit',
                textDecoration: 'underline',
                textTransform: 'uppercase',
                fontStyle: 'italic',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                fontWeight: 'normal',
                lineHeight: 1,
            },
        },
        submitButton: {
            fontFamily: theme.fonts.apex,
            fontSize: '24px',
            fontWeight: 'bold',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.secondary.main,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
                fontWeight: 'bold',
                marginTop: theme.spacing(3),
                padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
            },
            [theme.breakpoints.only('xs')]: {
                fontSize: '14px',
                padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
            }
        },
        textGoToForgetPassword: {
            fontFamily: theme.fonts.apex,
            fontSize: '12px',
            marginTop: theme.spacing(3),
            textAlign: "center",
            color: theme.palette.primary.main,
            '& a': {
                textDecoration: 'underline',
                color: theme.palette.primary.main,
            }
        },
        textGoToRegister:{
            fontFamily: theme.fonts.apex,
            fontSize: '12px',
            marginTop: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.primary.main,
            '& a': {
                textDecoration: 'underline',
                color: theme.palette.primary.main,
            }
        }
    }
});

export default useLoginModalStyles;