import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useMeetInfluencers = createUseStyles((theme: Theme) => {
    return {
        container: {
            backgroundColor: theme.colors.dark_chocolate,
            backgroundSize: 'cover',
            justifyContent: "center",
            alignItems: 'center',
        },
        imgBanner: {
            width: '100%',
        },
        containerItem: {
            display: "flex",
            justifyContent: "center",
            padding: theme.spacing(5),
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(1)}`,
            }
        },
        containerText: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
        },
        containerVideo: {
            display: "flex",
            padding: `${theme.spacing(3)} 0`,
            marginBottom: theme.spacing(3),
            justifyContent: "end",
            [theme.breakpoints.down('lg')]: {
                justifyContent: "center",
            }
        },
        containerSlideShow: {
            display: "flex",
            justifyContent: "center",
            padding: `${theme.spacing(1)} 0`,
        },
        textChallenge: {
            fontFamily: theme.fonts.apex,
            color: theme.palette.primary.light,
            fontSize: 'max(1.5vw,1.5vh)',
            textAlign: "center",
            '& .MuiLink-root' : {
                color: theme.palette.secondary.main,
            }
        },
        wrapperTextVideo:{
            margin: `${theme.spacing(3)}`,
            [theme.breakpoints.down('lg')]: {
            }
        },
        boxInfluencers: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            backgroundColor: theme.colors.latte_cream,
            margin: `${theme.spacing(3)}`,
            padding: `${theme.spacing(5)} ${theme.spacing(5)} 0 ${theme.spacing(5)}`,
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`,
            }
        },
        boxInfluencersHeadText: {
            fontFamily: theme.fonts.apex,
            color: theme.palette.primary.main,
            fontSize: 'max(1.5vw,1.5vh)',
            textAlign: 'center',
            fontWeight: "bold",
            padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(1.5vw,1.5vh)',
                padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            }
        },
        boxInfluencersTitle: {
            fontFamily: theme.fonts.poplar,
            color: theme.palette.primary.main,
            fontSize: 'max(2vw,2vh)',
            textAlign: 'center',
            fontWeight: 900,
            textTransform: 'uppercase',
            [theme.breakpoints.down('lg')]: {
                fontSize: 'max(2vw,2vh)',
            }
        },
        boxInfluencersImg: {
            width: '100%',
            height: 'auto',
            paddingTop: theme.spacing(5),
        },
        titleQuestDay: {
            fontFamily: theme.fonts.poplar,
            fontWeight: 900,
            fontSize: 'max(2.5vh,2.5vw)',
            color: theme.palette.secondary.light,
            textAlign: "center",
            padding: `${theme.spacing(0)}`,
            textTransform: 'uppercase',
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(1)}`,
            }
        },
    }
});

export default useMeetInfluencers;