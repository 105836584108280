import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import apiInstance from "../../api/api";
import axios from "axios";
import {handleAxiosError} from "../../utils/axiosErrorHandler";

export interface RegisterPayload {
    birthdate: string,
    phone: string,
    code: string,
    password: string,
    confirmPassword: string,
    join_group_code?: string,
}

interface ResponseError {
    message: string;
}

interface RegisterState {
    loading : boolean;
    isCodeSent : boolean;
    error: string;
    isRegisterSuccess : boolean;
}

export interface RegisterSendPhoneCode {
    birthdate: string,
    phone: string,
}

interface SendVerificationCodeResponse {
    success: boolean;
    message?: string;
}


const initialState:RegisterState = {
    loading : false,
    isCodeSent : false,
    isRegisterSuccess: false,
    error: '',

}

export const register = createAsyncThunk(
    'register/register',
    async (payload: RegisterPayload, thunkAPI) => {
        try {
            const response = await apiInstance.post('/register', payload)

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ResponseError);
        }
    }
);


export const sendPhoneCode = createAsyncThunk<
    SendVerificationCodeResponse,
    RegisterSendPhoneCode,
    { rejectValue: ResponseError }
>(
    'register/sendVerificationCode',
    async (payload: RegisterSendPhoneCode, thunkAPI) => {
        try {
            const response = await apiInstance.post<SendVerificationCodeResponse>('/register/send-code', payload);

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ResponseError);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.loading = true;
            })
            .addCase(register.fulfilled, (state) => {
                state.loading = false;
                state.isRegisterSuccess = true;
            })
            .addCase(register.rejected, (state) => {
                state.loading = false;
            })
            .addCase(sendPhoneCode.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendPhoneCode.fulfilled, (state) => {
                state.loading = false;
                state.isCodeSent = true;
            })
            .addCase(sendPhoneCode.rejected, (state) => {
                state.loading = false;
                state.isCodeSent = false;
            })
        ;
    },
});

export const {

} = authSlice.actions;

export default authSlice.reducer;