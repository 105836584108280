import React from "react";
import {Button, Grid, Typography} from "@mui/material";
import TeaserFrame2 from "../TeaserFrame2";
import icon_my_team from '../../assets/images/my_group/icon_my_team.png'
import logo_untold from '../../assets/images/logo_untold.png'
import useTeaserInviteFriends from "../../styles/my_group/teaserInviteFriends";
import theme from "../../styles/themes/default";

const TeaserInviteFriends: React.FC = () => {
    const teaserInviteFriends = useTeaserInviteFriends();
    return (
        <Grid container className={teaserInviteFriends.container}>
            <Grid item xs={12} className={teaserInviteFriends.containerItem}>
                <TeaserFrame2
                    title={<Typography className={teaserInviteFriends.teaserTitle}>Mixul meu de prieteni</Typography>}
                    titleMarginTop={'min(-3vh, -3vw)'}
                    backgroundBoxTitle={theme.colors.dark_chocolate}
                >
                    <Grid container className={teaserInviteFriends.teaserContentContainer}>
                        <Grid item xs={12} className={teaserInviteFriends.teaserContentContainerItem}>
                            <img src={icon_my_team} alt={'createGroup'}
                                 className={teaserInviteFriends.teaserContentImage}/>
                        </Grid>
                        <Grid item xs={12} className={teaserInviteFriends.teaserContentContainerItem}>
                            <Typography className={teaserInviteFriends.teaserContentDescription}>Kozel se savurează cel
                                mai bine împreună!</Typography>
                        </Grid>
                        <Grid item xs={12} className={teaserInviteFriends.teaserContentContainerItem}>
                            <Typography className={teaserInviteFriends.teaserContentDescription}>
                                Invită-ţi gașca de prieteni pe kozel.ro, formează-ţi mixul și bucuraţi-vă de premii și
                                experienţe ţapene la UNTOLD.</Typography>
                        </Grid>
                        <Grid item xs={12} className={teaserInviteFriends.teaserContentContainerItem}>
                            <img src={logo_untold} alt={'logo_untold'}
                                 className={teaserInviteFriends.teaserContentImage}/>
                        </Grid>
                        <Grid item xs={12} className={teaserInviteFriends.teaserContentContainerItem}>
                            <Button className={teaserInviteFriends.teaserContentButtonInvite}>INVITĂ PRIETENI</Button>
                        </Grid>
                    </Grid>
                </TeaserFrame2>
            </Grid>
        </Grid>
    );
}

export default TeaserInviteFriends;