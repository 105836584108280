import React from 'react';
import {Grid, Link, Typography} from "@mui/material";
import logo_big from '../assets/images/logo-big.png';
import promo_despre_alcool from '../assets/images/promo-despre-alcool.png';
import icon_fb from '../assets/images/icon_fb.svg'
import icon_insta from '../assets/images/icon_insta.svg'
import icon_yt from '../assets/images/icon_yt.svg'
import useFooterStyles from '../styles/footer';
import dayjs from "dayjs";
import {NavLink} from "react-router-dom";


const Footer: React.FC = () => {
    const footerStyle = useFooterStyles();

    return (
        <Grid container className={footerStyle.container}>
            <Grid item xs={12}>
                <img className={footerStyle.logoBig} src={logo_big} alt={'Kozel Logo'}/>
            </Grid>
            <Grid item xs={12}>
                <Typography className={footerStyle.socialLinksText}>Urmărește-ne</Typography>
            </Grid>
            <Grid item xs={12} className={footerStyle.wrapperSocialLinks}>
                <Link className={footerStyle.socialLinks} href={'https://www.facebook.com/kozelro'} target="_blank" rel="noopener">
                    <img className={footerStyle.socialLinksIcon} src={icon_fb} alt={'Facebook'}/>
                </Link>
                <Link className={footerStyle.socialLinks} href={'https://www.instagram.com/kozelbeer_ro/'} target="_blank" rel="noopener">
                    <img className={footerStyle.socialLinksIcon} src={icon_insta} alt={'Instagram'}/>
                </Link>
                <Link className={footerStyle.socialLinks} href={'https://www.youtube.com/channel/UCgG2hiWtd8ojGO97-I8Lj7Q'} target="_blank" rel="noopener">
                    <img className={footerStyle.socialLinksIcon} src={icon_yt} alt={'Youtube'}/>
                </Link>
            </Grid>
            <Grid item xs={12} className={footerStyle.aboutAlcohol}>
                <Link href={'https://www.desprealcool.ro'} target="_blank" rel="noopener">www.desprealcool.ro</Link>
            </Grid>
            <Grid item xs={12} className={footerStyle.wrapperLegalLinks}>
                <Link href={'/pdf/tc-ro.pdf'} className={footerStyle.legalLink} target="_blank" rel="noopener">Termeni
                    și condiții</Link>
                <NavLink to={'/regulamente'} className={footerStyle.legalLink}>Regulamente</NavLink>
                <Link href={'/pdf/content-policy-ro.pdf'} className={footerStyle.legalLink} target="_blank"
                      rel="noopener">Politica de conţinut în Social Media</Link>
                <Link
                    href={'/pdf/nota-de-informare_prelucrarea-datelor-cu-caracter-personal_site-kozel_obs-mv_v2_30-march-2022.pdf'}
                    className={footerStyle.legalLink} target="_blank" rel="noopener">Prelucrarea datelor cu caracter
                    personal</Link>
                <Link href={'https://anpc.ro/'} className={footerStyle.legalLink} target="_blank" rel="noopener">ANPC</Link>
            </Grid>
            <Grid item xs={12} className={footerStyle.copyright}>
                <Typography className={footerStyle.copyrightTypography}>copyright © {dayjs().year()} Ursus Breweries, Șoseaua Pipera
                    nr. 43, Sector 2, București, Cod poștal: 020112, cod unic de înmatriculare RO199095. Toate
                    drepturile rezervate. Vă rugăm nu distribuiți conținutul persoanelor care nu au împlinit încă vârsta
                    de 18 ani.</Typography>
            </Grid>
            <Grid item xs={12} className={footerStyle.aboutAlcoholIcon}>
                <Link href={'https://www.desprealcool.ro'} target="_blank" rel="noopener"><img src={promo_despre_alcool} alt={'Despre Alcool'}/></Link>
            </Grid>

        </Grid>
    );
};

export default Footer;