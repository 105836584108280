import React from 'react';
import {Grid, Paper} from "@mui/material";
import clsx from "clsx";
import icon_arrow from "../assets/images/icon_arrow.png";
import useTeaserStyles from "../styles/teaser_styles";

interface TeaserComponentProps {
    title: React.ReactNode;
    content: React.ReactNode;
}
const TeaserComponent: React.FC<TeaserComponentProps> = ({title, content}) => {

    const teaserStyles = useTeaserStyles();

    return (
        <Grid container>
            <Grid item xs={12} className={clsx(teaserStyles.section, teaserStyles.transparent)}>
                <img src={icon_arrow} alt={'arrow_left'} className={teaserStyles.arrowLeft}/>
                <img src={icon_arrow} alt={'arrow_right'} className={teaserStyles.arrowRight}/>
                <Grid container justifyContent={'center'}>
                    <Grid item md={8} sm={12} xs={12}>
                        <Grid container>
                            <Grid item xs={12} className={teaserStyles.boxTitle}>
                                {title}
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={teaserStyles.paperForm}>
                                    {content}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TeaserComponent;