import React from 'react';
import {Box, Modal} from "@mui/material";
import useModalWrapperStyles from "../styles/modalWrapper";
interface ModalWrapperProps {
    isOpen: boolean;
    onRequestClose: () => void;
    children: React.ReactNode;
}
const ModalWrapper: React.FC<ModalWrapperProps> =({ isOpen, onRequestClose, children }) => {

    const modalWrapperStyles = useModalWrapperStyles();

    return (
        <Modal
            open={isOpen}
            onClose={onRequestClose}
            aria-labelledby="personal-data-form-title"
            aria-describedby="personal-data-form-description"
        >
            <Box className={modalWrapperStyles.container}>
                {children}
            </Box>
        </Modal>
    )
};

export default ModalWrapper;