import {createUseStyles} from 'react-jss';
import {Theme} from './themes/default';
import navbarBgImg from '../assets/images/patternAlb.jpg';
import bodyBgImg from "../assets/images/patternAlb.jpg";

const useNavbarStyles = createUseStyles((theme: Theme) => {
    return {
        container: {

        },
        appBar: {
            backgroundImage: `url(${navbarBgImg})`,
            backgroundRepeat: 'repeat',
        },
        toolbar:{
            display: 'flex',
            justifyContent: 'space-between',
        },
        logo:{
            height: '100%',
            width: 'auto',
            padding: theme.spacing(2),
            maxHeight: '50px',
        },
        burgerButton: {
            color: theme.palette.primary.main
        },
        burgerIcon: {
            color: theme.palette.primary.main
        },
        navbarDrawer: {
            '& .MuiPaper-root': {
                backgroundImage: `url(${bodyBgImg})`,
                width: '30vw',
                [theme.breakpoints.down('sm')]:{
                    width: '75vw',
                }
            },
        },
        navbarDrawerList: {
            marginTop: theme.spacing(5),
        },
        navbarDrawerListItem: {
            marginRight: theme.spacing(5),
            paddingRight: theme.spacing(5),
            marginTop: theme.spacing(1),
            textAlign: "right"

        },
        navbarDrawerListItemText: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(2vh,2vw)',
            fontWeight: "bold",
            lineHeight: 1,
            paddingBottom: theme.spacing(1),
            color: theme.palette.primary.main,
            '& .MuiTypography-root':{
                fontSize: 'max(2vh,2vw)',
                fontWeight: "bold",
                lineHeight: 1,
            },
        },
        button: {
            display: "block",
            padding: '10px 20px',
            margin: '10px 20px',
            fontSize: '16px',
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.primary.main,
            },
        },
    }
});

export default useNavbarStyles;