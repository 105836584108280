import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiInstance from '../../api/api';
import {logout} from "../auth/authSlice";

export interface ProfileState {
    id: number|null,
    crm_id: number | null,
    nick_name: string | null | undefined,
    phone: string,
    loading: boolean,
    coins: bigint | null,
}

const initialState: ProfileState = {
    id: null,
    crm_id: null,
    nick_name: null,
    phone: '',
    loading: false,
    coins: null,
}

export const getProfile = createAsyncThunk(
    'profile/profile',
    async () => {
        const response = await apiInstance.get('/profile');

        return response.data;
    }
);

export const updateProfile = createAsyncThunk(
    'profile/updateProfile',
    async (nick_name: string) => {
        const response = await apiInstance.post('/profile', {nick_name});
        return response.data;
    }
);

export const getCoins = createAsyncThunk(
    'contact/getCoins',
    async () => {
        const response = await apiInstance.get('/contact/coins');

        return response.data;
    }
)

const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getProfile.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.id = action.payload.id
                state.crm_id = action.payload.crm_id
                state.nick_name = action.payload.nick_name
                state.phone = action.payload.phone
            })
            .addCase(getProfile.rejected, (state, action) => {
                state.loading = false;
                console.log(' hase some errors => ', state, action)
            })
            .addCase(updateProfile.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.nick_name = action.payload.nick_name;
                state.loading = false;
            })
            .addCase(updateProfile.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getCoins.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCoins.fulfilled, (state, action) => {
                state.loading = false;
                state.coins = action.payload.coins
            })
            .addCase(getCoins.rejected, (state) => {
                state.loading = false;
            })
            .addCase(logout,(state) => {
                return initialState;
            })
        ;
    }
});

// export const {} = profileSlice.actions
export default profileSlice.reducer;