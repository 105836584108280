import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import apiInstance from '../../api/api';
import {getPersonalData} from "../profile/personalDataProfileSlice";
import {handleAxiosError, ResponseError} from "../../utils/axiosErrorHandler";
import {showNotification} from "../notification/notificationSlice";

interface AuthState {
    isAuthenticated: boolean;
    accessToken: string | null;
    refreshToken: string | null;
    error: string;
    loading: boolean;
    isLoginModalOpen: boolean;
    isRegisterModalOpen: boolean;
    isPersonalDataModalOpen: boolean;
    hasIdentity: boolean;
}

const initialState: AuthState = {
    isAuthenticated: false,
    accessToken: null,
    refreshToken: null,
    error: '',
    loading: false,
    isLoginModalOpen: false,
    isRegisterModalOpen: false,
    isPersonalDataModalOpen: false,
    hasIdentity: false,
};

interface AuthResponse {
    accessToken: string;
    refreshToken: string;
    hasIdentity: boolean;
}

interface AuthCodeError {
    message: string;
}

interface AuthArgs {
    phone: string;
    password: string;
    keepLoggedIn: boolean;
}

interface PersonalDataArgs {
    // firstName: string;
    // lastName: string;
    email: string | null;
    birthdate: string;
    // accordMarketing: boolean;
    // accordMarketingSms: boolean;
    accordMarketingEmail: boolean;
}

export const login = createAsyncThunk<
    AuthResponse,
    AuthArgs,
    { rejectValue: AuthCodeError }
>(
    'login/login',
    async ({phone, password, keepLoggedIn}: AuthArgs, thunkAPI) => {
        try {
            const response = await apiInstance.post<AuthResponse>('/login', {
                phone,
                password,
            });
            const {accessToken, refreshToken} = response.data;
            let hasIdentity = false;

            if (keepLoggedIn) {
                localStorage.setItem('access_token', accessToken);
                localStorage.setItem('refresh_token', refreshToken);
                localStorage.setItem('keep_logged_in', 'true');
            } else {
                sessionStorage.setItem('access_token', accessToken);
                sessionStorage.setItem('refresh_token', refreshToken);
                localStorage.removeItem('keep_logged_in');
            }

            return {accessToken: accessToken, refreshToken: refreshToken, hasIdentity: hasIdentity};

        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ResponseError);
        }
    }
);


export const autoLogin = createAsyncThunk(
    'login/autoLogin',
    async (_, thunkAPI) => {

        const keepLoggedIn = localStorage.getItem('keep_logged_in') === 'true';
        const storage = keepLoggedIn ? localStorage : sessionStorage;

        const accessToken = storage.getItem('access_token');
        const refreshToken = storage.getItem('refresh_token');

        if (accessToken && refreshToken) {
            return {accessToken, refreshToken};
        } else {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('keep_logged_in');
            sessionStorage.removeItem('access_token');
            sessionStorage.removeItem('refresh_token');
        }
    }
);

interface PayloadLoginMock extends AuthState {

}

export const updateAccessToken = createAsyncThunk(
    'login/updateToken',
    async (_, thunkAPI) => {
        try {
            const refreshToken = localStorage.getItem('refresh_token');
            const response = await apiInstance.post('/refresh', {refreshToken});

            const {access_token, refresh_token} = response.data;


            localStorage.setItem('access_token', access_token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('keep_logged_in', 'true');
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ResponseError);
        }
    }
)

export const validateToken = createAsyncThunk(
    'login/validateToken',
    async (_, thunkAPI) => {
        try {
            const response = await apiInstance.get('/validate-token');

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ResponseError);
        }
    }
)

export const updatePersonalData = createAsyncThunk(
    'contact/updatePersonalData',
    async ({
               // firstName,
               // lastName,
               email,
               birthdate,
               // accordMarketing,
               // accordMarketingSms,
               accordMarketingEmail
           }: PersonalDataArgs, thunkAPI) => {
        try {
            const response = await apiInstance.post('/personal-data', {
                // firstName,
                // lastName,
                email,
                birthdate,
                // accordMarketing,
                // accordMarketingSms,
                accordMarketingEmail,
            });

            await thunkAPI.dispatch(getPersonalData());
            thunkAPI.dispatch(showNotification({message: response.data['message'], variant: 'success'}));
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ResponseError);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('keep_logged_in');
            sessionStorage.removeItem('access_token');

            return initialState;
        },
        openLoginModal(state) {
            state.isLoginModalOpen = true;
        },
        closeLoginModal(state) {
            console.log('modalul de login se inchide');
            state.isLoginModalOpen = false;
        },
        mockLogin(state, data: PayloadAction<PayloadLoginMock>) {
            state.isAuthenticated = data.payload.isAuthenticated;
            state.accessToken = data.payload.accessToken;
            state.refreshToken = data.payload.refreshToken;
            state.error = data.payload.error;
            state.loading = data.payload.loading;
            state.isLoginModalOpen = data.payload.isLoginModalOpen;
            state.isRegisterModalOpen = data.payload.isRegisterModalOpen;
            state.isPersonalDataModalOpen = data.payload.isPersonalDataModalOpen;
            state.hasIdentity = data.payload.hasIdentity;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(login.fulfilled, (state, action) => {
                console.log('a intrat pe ramura de login fulfilled');
                state.loading = false;
                state.isAuthenticated = true;
                state.isLoginModalOpen = false;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload?.message || 'Failed to login. Please check your code.';
                // state.isLoginModalOpen = true;
            })
            .addCase(autoLogin.pending, (state, action) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(autoLogin.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.isLoginModalOpen = false;
            })
            .addCase(autoLogin.rejected, (state, action) => {
                state.loading = false;
                state.error = 'Failed to auto login.';
            })
            .addCase(updateAccessToken.pending, (state, action) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(updateAccessToken.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.isLoginModalOpen = false;
            })
            .addCase(updateAccessToken.rejected, (state, action) => {
                state.loading = false;
                state.error = 'Failed to keep login.';
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('keep_logged_in');
            })
            .addCase(validateToken.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(validateToken.fulfilled, (state, action) => {
                state.loading = false;
                state.isAuthenticated = action.payload.valid
            })
            .addCase(validateToken.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = false;
            })
            .addCase(updatePersonalData.pending, state => {
                state.loading = true;
            })
            .addCase(updatePersonalData.fulfilled, state => {
                state.loading = false;
                state.hasIdentity = true;
                state.isPersonalDataModalOpen = false;
            })
            .addCase(updatePersonalData.rejected, state => {
                state.loading = false;
                state.hasIdentity = false;
            })
        ;
    },
});

export const {
    logout,
    openLoginModal,
    closeLoginModal,
    mockLogin,
} = authSlice.actions;

export default authSlice.reducer;
