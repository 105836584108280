import axios from 'axios';
import store  from '../stores/store';
import { logout } from '../features/auth/authSlice';


const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

const apiInstance = axios.create({
    baseURL: apiBaseURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
});
apiInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        const originalAuthHeader = originalRequest.headers['Authorization'];
        // check if request response with 401 and contain Bearer
        if (error.response.status === 401 && !originalRequest._retry && originalAuthHeader && originalAuthHeader.startsWith('Bearer ')) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refresh_token');

            if (refreshToken) {
                try {
                    const response = await axios.post(`${apiBaseURL}/refresh`, null, {
                        headers: {
                            'Authorization': `Bearer ${refreshToken}`
                        }
                    });

                    const newAccessToken = response.data.access_token;
                    localStorage.setItem('access_token', newAccessToken);

                    apiInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                    originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                    return apiInstance(originalRequest);
                } catch (refreshError) {
                    // Dispatch logout if refresh token is also expired or invalid
                    store.dispatch(logout());
                    return Promise.reject(refreshError);
                }
            } else {
                store.dispatch(logout());
            }
        }

        return Promise.reject(error);
    }
);

export default apiInstance;
