import React from 'react';
import {Grid, Typography} from "@mui/material";
import clsx from "clsx";
import useOurBeersStyles from "../styles/our_beers";
import Carousel from "react-material-ui-carousel";
import lager_sticla from '../assets/images/lager_sticla.png';
import lager_doza from '../assets/images/lager_doza.png';
import dark_sticla from '../assets/images/dark_sticla.png';
import dark_doza from '../assets/images/dark_doza.png';
import DidYouKnowPresentation from "../components/DidYouKnowPresentation";

const OurBeers: React.FC = () => {

    const ourBeersStyles = useOurBeersStyles();

    return (
        <Grid container>
            <Grid item xs={12} className={clsx(ourBeersStyles.section, ourBeersStyles.bgImgLight)}>
                <Grid container>
                    <Grid item sm={12} md={6}>
                        <Typography className={ourBeersStyles.sectionTitle}>Kozel Premium Larger</Typography>
                        <Typography className={ourBeersStyles.sectionSubtitle}>Kozel Premium este cea mai populară bere cehă din lume.
                            Kozel Premium are ca ingrediente trei tipuri de malț atent selecționate și hamei de aromă,
                            pentru un gust echilibrat, potrivit de amărui, cu <br/><b>4.6%alc.</b></Typography>
                    </Grid>
                    <Grid item sm={12} md={6} className={clsx(ourBeersStyles.carouselContainer, ourBeersStyles.carouselRight)}>
                        <Carousel
                            className={ourBeersStyles.carousel}
                            navButtonsAlwaysInvisible
                            animation={'fade'}
                            indicators={true}
                            indicatorContainerProps={{
                                className: ourBeersStyles.indicatorContainer
                            }}
                            indicatorIconButtonProps={{
                                className: ourBeersStyles.indicator
                            }}
                            activeIndicatorIconButtonProps={{
                                className: ourBeersStyles.activeIndicator
                            }}
                        >
                            <img src={lager_sticla} alt={'lager_sticla'} className={ourBeersStyles.carouselImage}/>
                            <img src={lager_doza} alt={'lager_doza'} className={ourBeersStyles.carouselImage}/>
                        </Carousel>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={clsx(ourBeersStyles.section, ourBeersStyles.bgImgDark)}>
                <Grid container>
                    <Grid item sm={12} md={6} className={clsx(ourBeersStyles.carouselContainer, ourBeersStyles.carouselLeft)}>
                        <Carousel
                            className={ourBeersStyles.carousel}
                            navButtonsAlwaysInvisible
                            animation={'fade'}
                            indicators={true}
                            indicatorContainerProps={{
                                className: ourBeersStyles.indicatorContainer
                            }}
                            indicatorIconButtonProps={{
                                className: ourBeersStyles.indicator
                            }}
                            activeIndicatorIconButtonProps={{
                                className: ourBeersStyles.activeIndicator
                            }}
                        >
                            <img src={dark_sticla} alt={'lager_sticla'} className={ourBeersStyles.carouselImage}/>
                            <img src={dark_doza} alt={'lager_doza'} className={ourBeersStyles.carouselImage}/>
                        </Carousel>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Typography className={clsx(ourBeersStyles.sectionTitle,'contrast')}>Kozel Dark</Typography>
                        <Typography className={clsx(ourBeersStyles.sectionSubtitle,'contrast')}>Kozel Dark poate părea intens, puternic, dar nu te lăsa păcălit!
                            E o bere brună surprinzător de răcoritoare, care te va uimi cu finețea ei, culoarea unică și
                            notele de caramel obținute în urma combinării a patru tipuri de
                            malț.<br/><b>3.7%alc.</b></Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DidYouKnowPresentation title={'LUCRURI ȚAPENE'} subtitle={'KOZEL ÎNSEAMNĂ ȚAP ÎN CEHĂ'}/>
            </Grid>
        </Grid>
    );
}

export default OurBeers;