import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import imgMixTapan from '../../assets/images/ncp/ncp_1.png';
import ncp_premiu1 from '../../assets/images/ncp/ncp_premiu1.png';
import ncp_premiu2 from '../../assets/images/ncp/ncp_premiu2.png';
import promo_despre_alcool from '../../assets/images/promo-despre-alcool.png';
import useMixTapanStyles from "../../styles/ncp/mix_tapan";
import ImageComponent from "../../utils/ImageComponent";
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import logo_carrefour_dark from '../../assets/images/ncp/logo_carrefour.png';
import logo_auchan_dark from '../../assets/images/ncp/logo_auchan.png';
import logo_mega_dark from '../../assets/images/ncp/logo_mega.png';
import logo_kaufland_dark from '../../assets/images/ncp/logo_kaufland.png';
import logo_profi_dark from '../../assets/images/ncp/logo_profi.png';
import logo_penny_dark from '../../assets/images/ncp/logo_penny.png';

const MixTapan: React.FC = () => {
    const mixTapanStyle = useMixTapanStyles();
    const [showDialog, setShowDialog] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpenDialogRegulamente = () => {
        setShowDialog(true);
    };

    const handleCloseDialogRegulamente = () => {
        setShowDialog(false);
    };

    return (
        <Grid container>
            <Grid item xs={12} md={6} className={mixTapanStyle.boxLeft}>
                <ImageComponent src={imgMixTapan} alt={'Mix tapan de festival'}/>
                <Typography className={mixTapanStyle.textSecondary}>Încearcă MIX-ul Kozel și poți câștiga premii țapene
                    de festival</Typography>
                <Button className={mixTapanStyle.buttonRegulament}
                        onClick={handleClickOpenDialogRegulamente}>Regulament</Button>
            </Grid>
            <Grid item xs={12} md={6} className={mixTapanStyle.boxRight}>
                <Grid container>
                    <Grid item xs={12} className={mixTapanStyle.wrapperRightText}>
                        <Typography className={mixTapanStyle.textPrimary}>Cumpără Kozel Premium sau Kozel Dark în orice
                            combinație, de minim 25 lei pe un singur bon fiscal, înscrie bonul aici și poți
                            câștiga:</Typography>
                        <ImageComponent src={ncp_premiu1} alt={'Premiu 1'}/>
                        <ImageComponent src={ncp_premiu2} alt={'Premiu 2'}/>
                    </Grid>
                    <Grid item xs={12} className={mixTapanStyle.promotionInterval}>
                        <Typography>Durata promotiei: <b>01.07.2024 - 31.07.2024</b></Typography>
                        <img src={promo_despre_alcool} alt={'promo despre alcool'}/>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                fullScreen={fullScreen}
                open={showDialog}
                onClose={handleCloseDialogRegulamente}
                aria-labelledby="responsive-dialog-title"
                className={mixTapanStyle.dialog}
            >
                <DialogTitle id="responsive-dialog-title" className={mixTapanStyle.dialogTitle}>
                    {"Regulament"}
                </DialogTitle>
                <DialogContent className={mixTapanStyle.dialogContent}>
                    <List className={mixTapanStyle.dialogContentList}>
                        <ListItem disablePadding>
                            <ListItemButton component="a"
                                            href="/pdf/2024/10-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Carrefour.pdf"
                                            target="_blank" rel="noopener">
                                <ListItemIcon>
                                    <img alt="logo_carrefour_dark" src={logo_carrefour_dark}/>
                                </ListItemIcon>
                                <ListItemText primary={"Carrefour"}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a"
                                            href="/pdf/2024/11-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Auchan.pdf” - Carrefour.pdf"
                                            target="_blank" rel="noopener">
                                <ListItemIcon>
                                    <img alt="logo_auchan_dark" src={logo_auchan_dark}/>
                                </ListItemIcon>
                                <ListItemText primary={"Auchan"}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a"
                                            href="/pdf/2024/12-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Kaufland.pdf” - Carrefour.pdf"
                                            target="_blank" rel="noopener">
                                <ListItemIcon>
                                    <img alt="logo_kaufland_dark" src={logo_kaufland_dark}/>
                                </ListItemIcon>
                                <ListItemText primary={"Kaufland"}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a"
                                            href="/pdf/2024/13-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Mega Image.pdf” - Carrefour.pdf"
                                            target="_blank" rel="noopener">
                                <ListItemIcon>
                                    <img alt="logo_mega_dark" src={logo_mega_dark}/>
                                </ListItemIcon>
                                <ListItemText primary={"Mega Image"}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a"
                                            href="/pdf/2024/14-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Penny.pdf"
                                            target="_blank" rel="noopener">
                                <ListItemIcon>
                                    <img alt="logo_penny_dark" src={logo_penny_dark}/>
                                </ListItemIcon>
                                <ListItemText primary={"Penny"}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a"
                                            href="/pdf/2024/15-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival“ - Profi.pdf"
                                            target="_blank" rel="noopener">
                                <ListItemIcon>
                                    <img alt="logo_profi_dark" src={logo_profi_dark}/>
                                </ListItemIcon>
                                <ListItemText primary={"Profi"}/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color={'primary'} onClick={handleCloseDialogRegulamente}
                            className={mixTapanStyle.buttonCloseModal}>Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

export default MixTapan;