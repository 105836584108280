import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useWhatNext = createUseStyles((theme: Theme) => {
    return {
        container: {
            backgroundColor: theme.colors.dark_chocolate,
            backgroundSize: 'cover',
        },
        containerItem: {},
        containerTeaser: {
            padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
            marginBottom: theme.spacing(10),
            [theme.breakpoints.down('lg')]:{
                padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
            }
        },

        message: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vh,3vw)',
            color: theme.colors.latte_cream,
            fontWeight: 900,
            textAlign: "center",
            padding: `${theme.spacing(5)} 0`,
        },
        teaserTitle: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(3vh,3vw)',
            color: theme.palette.primary.light,
            fontWeight: 900,
            textWrap: 'nowrap',
            [theme.breakpoints.down('lg')]:{
                fontSize: 'max(3.5vh,3.5vw)',
            }
        },
        teaserContentContainer: {
            padding: theme.spacing(5),
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down('lg')]:{
                padding: `${theme.spacing(2)}`,
            }
        },
        teaserContentContainerItem: {
            padding: `${theme.spacing(2)}`,
            display: "flex",
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down('lg')]:{
                padding: `${theme.spacing(1)}`,
            }
        },
        teaserContentText1: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.colors.latte_cream,
            textAlign: 'center',
            fontWeight: 900,
            [theme.breakpoints.down('lg')]:{
                fontSize: 'max(2vh,2vw)',
            }
        },
        teaserContentText2: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1.5vh,1.5vw)',
            color: theme.palette.primary.light,
            textAlign: 'center',
            fontWeight: 'bold',
            [theme.breakpoints.down('lg')]:{
                fontSize: 'max(2vh,2vw)',
            }
        },
        teaserContentButton: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2vh,2vw)',
            color: theme.colors.dark_chocolate,
            textAlign: 'center',
            backgroundColor: theme.colors.latte_cream,
            textTransform: 'uppercase',
            fontWeight: 900,
            padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
            marginTop:  theme.spacing(3),
            '&:hover':{
                backgroundColor: theme.colors.dark_chocolate,
                color: theme.colors.latte_cream,
            },
            [theme.breakpoints.down('lg')]:{
                fontSize: 'max(2.5vh,2.5vw)',
            }
        },
    }
});

export default useWhatNext;