// styles.ts
import {createUseStyles} from 'react-jss';
import {Theme} from './themes/default';
import bodyBgImg from '../assets/images/patternAlb.jpg'

const useGlobalStyles = createUseStyles((theme: Theme) => {
        return {
            "@global": {
                body: {
                    backgroundImage: `url(${bodyBgImg})`,
                    backgroundRepeat: 'repeat',
                },
                a: {
                    textDecoration: 'none'
                }
            },
            container: {
                textAlign: 'center',
            },
            button: {
                display: "block",
                padding: '10px 20px',
                margin: '10px 20px',
                fontSize: '16px',
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.primary.main,
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.primary.main,
                },
            },
            circularProgress: {
                background: 'aqua',
                border: '1px solid red'
            },
        }
    }
);

export default useGlobalStyles;
