import React from 'react';
import {Grid, Paper, Typography} from "@mui/material";
import clsx from "clsx";
import icon_arrow from "../assets/images/icon_arrow.png";
import didYouKnowStyles from "../styles/did_you_know_styles";

interface DidYouKnowComponentProps {
    title: React.ReactNode;
    children: React.ReactNode;
}
const DidYouKnowComponent: React.FC<DidYouKnowComponentProps> = ({title, children}) => {

    const didYouKnowComponentStyles = didYouKnowStyles();

    return (
        <Grid container>
            <Grid item xs={12} className={clsx(didYouKnowComponentStyles.section)}>
                <img src={icon_arrow} alt={'arrow_left'} className={didYouKnowComponentStyles.arrowLeft}/>
                <img src={icon_arrow} alt={'arrow_right'} className={didYouKnowComponentStyles.arrowRight}/>
                <Grid container justifyContent={'center'}>
                    <Grid item md={8} sm={12} xs={12}>
                        <Grid container>
                            <Grid item xs={12} className={didYouKnowComponentStyles.boxTitle}>
                                <Typography className={didYouKnowComponentStyles.title} textAlign={'center'}>{title}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={didYouKnowComponentStyles.paperForm}>
                                    {children}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DidYouKnowComponent;