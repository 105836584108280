import axios from 'axios';

export interface ResponseError {
    message: string;
}

export interface RejectedWithValueAction{
    type: string
    payload: ResponseError
    error: { message: 'Rejected' }
}

export const handleAxiosError = (error: unknown): ResponseError => {
    if (axios.isAxiosError(error)) {
        if (error.response) {
            return { message: error.response.data.message || 'A aparut o eroare necunoscuta' };
        } else if (error.request) {
            return { message: 'Eroare necunoscuta' };
        } else {
            return { message: error.message };
        }
    } else {
        return { message: 'Eroare necunoscuta' };
    }
};
