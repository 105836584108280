import {createUseStyles} from "react-jss";
import {Theme} from "./themes/default";


const useRegulamenteStyles = createUseStyles((theme: Theme) => {
        return {
            title: {
                fontFamily: theme.fonts.poplar,
                fontSize: 'max(6vw,6vh)',
                textAlign: 'center',
                padding: theme.spacing(10),
            },
            wrapperAccordion: {
                padding: theme.spacing(4),
                [theme.breakpoints.down('lg')]: {
                    padding: theme.spacing(2),
                }
            },
            accordionSummary: {
                background: theme.palette.background.default,
                '& .MuiAccordionSummary-content': {
                    fontFamily: theme.fonts.apex,
                    fontSize: 'max(1.6vh,1.6vw)',
                    fontWeight: "bold",
                    justifyContent: 'center',
                }
            },
            accordionDetails: {
                background: theme.palette.background.paper,
            },
            list: {
                '& .MuiListItemText-root span': {
                    fontFamily: theme.fonts.apex,
                    fontSize: 'max(1.2vh,1.2vw)',
                    color: theme.palette.primary.main,
                }
            }

        }
    }
);


export default useRegulamenteStyles;