import { createUseStyles } from 'react-jss';
import {Theme} from "./themes/default";

const useCarouselThumbsImagesStyles = createUseStyles((theme: Theme) => {
    return {
        slideContainer: {
            width: '100%',
            overflow: 'hidden',
            position: 'relative',
        },
        slider: {
            display: 'flex',
            alignItems: 'center',
        },
        slideTrack: {
            display: 'flex',
            width: 'calc(1000%)',
            animation: '$scroll 80s linear infinite',
        },
        slideTrackBackward: {
            display: 'flex',
            width: 'calc(1000%)',
            animation: '$scroll 80s linear infinite',
        },
        slide: {
            flexShrink: 0,
            marginLeft: `${theme.spacing(2)}`,
        },
        slideImg: {
            height: '100%',
            width: '100%',
            objectFit: "cover",
        },
        '@keyframes scroll': {
            '0%': {
                transform: 'translateX(0)',
            },
            '100%': {
                transform: 'translateX(-50%)',
            },
        },
    }
});

export default useCarouselThumbsImagesStyles;
