import React, {ReactNode} from 'react';
import {Box, Typography} from "@mui/material";
import useStepStyles from "../../styles/ncp/step";
import {useInView} from "react-intersection-observer";

interface StepsProps {
    children: ReactNode,
    index: number,
}
const Step: React.FC<StepsProps> = ({children,index}) => {
    const stepStyles = useStepStyles();
    const {ref, inView} = useInView({
        threshold: 0.2,
    });
    const { ref:refIndex, inView:inViewIndex} = useInView({
        threshold: 0.2,
    });

    return (
        <Box className={stepStyles.box}>
            <Typography ref={refIndex} className={`${stepStyles.index} ${inViewIndex ? 'visible' : ''}`}>{index}</Typography>
            <Box ref={ref}  className={`${stepStyles.textFrame} ${inView ? 'visible' : ''}` }>
                <Typography className={stepStyles.typography}>{children}</Typography>
            </Box>
        </Box>
    )
}
export default Step;