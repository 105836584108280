import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarMessage, OptionsObject } from 'notistack';

interface NotificationMessage {
    message: SnackbarMessage;
    options?: OptionsObject;
}

interface NotificationState {
    messages: NotificationMessage[];
}

const initialState: NotificationState = {
    messages: [],
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        showNotification: (state, action: PayloadAction<{ message: string; variant: 'default' | 'error' | 'success' | 'warning' | 'info' }>) => {
            state.messages.push({
                message: action.payload.message,
                options: {
                    variant: action.payload.variant,
                },
            });
        },
        clearNotifications: (state) => {
            state.messages = [];
        },
    },
});

export const { showNotification, clearNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;
