import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiInstance from '../../api/api';
import axios from "axios";
import {showNotification} from "../notification/notificationSlice";

export interface NcpPayloadProps {
    partner: string;
    birthdate: string;
    firstname: string;
    lastname: string;
    phone: string;
    receiptNr: string;
    receiptDate: string;
    receiptFile: string;
    accordingMarketing: boolean;
}
export interface NcpStateProps {
    loading: boolean;
}

const initialState: NcpStateProps = {
    loading: false,
}

export const insertNcp = createAsyncThunk(
    'ncp/insertNcp',
    async (payload: NcpPayloadProps, thunkAPI) => {
        try{
            const response = await apiInstance.post('/ncp/insert', payload);
            thunkAPI.dispatch(showNotification({message: response.data['message'], variant: 'success'}));

            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    thunkAPI.dispatch(showNotification({message: error?.response?.data?.message || 'Eroare înregistrare bon!', variant: 'error'}));
                    return thunkAPI.rejectWithValue(error.response.data);
                } else if (error.request) {
                    thunkAPI.dispatch(showNotification({message: 'Eroare înregistrare bon!', variant: 'error'}));
                    return thunkAPI.rejectWithValue({message: 'No response from server'});
                } else {
                    thunkAPI.dispatch(showNotification({message: 'Eroare înregistrare bon!', variant: 'error'}));
                    return thunkAPI.rejectWithValue({message: error.message});
                }
            } else {
                thunkAPI.dispatch(showNotification({message: 'Eroare înregistrare bon!', variant: 'error'}));
                return thunkAPI.rejectWithValue({message: 'An unknown error occurred'});
            }
        }
    }
);


const ncpSlice = createSlice({
    name: "ncp",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(insertNcp.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(insertNcp.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(insertNcp.rejected, (state, action) => {
                state.loading = false;
            })
        ;
    }
});

// export const {} = profileSlice.actions
export default ncpSlice.reducer;