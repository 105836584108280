// notificationMiddleware.ts
import { Middleware } from '@reduxjs/toolkit';
import { showNotification } from '../../features/notification/notificationSlice';
import { enqueueSnackbar } from '../../utils/notificationManager';

const notificationMiddleware: Middleware = (store) => (next) => (action) => {
    if (showNotification.match(action)) {
        const { message, variant } = action.payload;
        enqueueSnackbar(message, {
            variant,
        });
    }
    return next(action);
};

export default notificationMiddleware;
