import React from "react";
import {Grid, Link, Typography} from "@mui/material";
import banner_meet from "../../assets/images/kozel_meet/banner_meet.png";
import poza_influenceri from '../../assets/images/kozel_meet/poza_influenceri.png'
import useMeetInfluencers from "../../styles/kozelMeet/meetInfluencers";
import video_influencers from '../../assets/videos/video_influencers.webm';
import clsx from "clsx";
import imagesCarousels from './importImages';
import SlideShow from "../SlideShow";
import VideoPlayer from "../VideoPlayer";
import {useAppSelector} from "../../stores/store";
import ImageComponent from "../../utils/ImageComponent";
import logo_untold from '../../assets/images/logo_untold.png'

const MeetInfluencers: React.FC = () => {
    const meetInfluencers = useMeetInfluencers();
    const {isAuthenticated} =useAppSelector((state) => state.auth);
    return (
        <Grid container className={meetInfluencers.container}>
            <Grid item xs={12} className={meetInfluencers.containerItem}>
                <img src={banner_meet} alt={'poza banner'} className={meetInfluencers.imgBanner}/>
            </Grid>
            <Grid item xs={12} lg={10} className={meetInfluencers.containerItem}>
                <Typography className={meetInfluencers.textChallenge}>I-am provocat pe <Link href={'https://www.instagram.com/lauramusuroaea/'} target="_blank">@LauraMusuroaca</Link>
                    , <Link href={'https://www.instagram.com/mariciu/'} target="_blank">@Mariciu</Link>&nbsp;
                    si&nbsp;<Link href={'https://www.instagram.com/vladgrigorescu/'} target="_blank">@VladGrigorescu</Link> să-și
                    creeze echipele și să rezolve provocările ȚAPene Kozel!</Typography>
            </Grid>
            <Grid item xs={12} lg={10} className={clsx(meetInfluencers.boxInfluencers)}>
                <Grid container>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                        <Typography className={meetInfluencers.boxInfluencersHeadText}>Multi dintre voi le-ati trimis intentiile de a fi parte din echipa lor, iar ei au apreciat fiecare dintre video-urile voastre creative.</Typography>
                        <Typography className={meetInfluencers.boxInfluencersHeadText}>Odată ce au fost formate, cele 3 echipe s-au duelat și surpriză mareeeeee ... toate au câștigat!</Typography>
                        <Typography className={meetInfluencers.boxInfluencersTitle}>Ne vedem toți la UNTOLD să dăm startul Kozel Meeet QUEST!</Typography>
                        <Typography className={meetInfluencers.boxInfluencersTitle}>Ei au deja echipele formate!</Typography>
                        <img src={poza_influenceri} alt={'poza_influenceri'} className={meetInfluencers.boxInfluencersImg} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} lg={10} className={meetInfluencers.wrapperTextVideo}>
                <Grid container>
                    <Grid item xs={12} lg={6} className={meetInfluencers.containerText}>
                        {isAuthenticated && <ImageComponent src={logo_untold} alt={'logo_untold'} /> }
                        <Typography className={meetInfluencers.titleQuestDay}>
                            Până atunci, vezi cum s-au distrat și provocat cele 3 echipe la QUEST DAY:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} className={meetInfluencers.containerVideo}>
                        <VideoPlayer src={video_influencers} type="video/webm" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={meetInfluencers.containerSlideShow}>
                <SlideShow
                    duration={10}
                    isForwardDir={true}
                    images={imagesCarousels.filter((_, index) => index % 2 === 0)}
                    slidesToShow={5}
                />
            </Grid>
            <Grid item xs={12} className={meetInfluencers.containerSlideShow}>
                <SlideShow
                    duration={10}
                    isForwardDir={false}
                    images={imagesCarousels.filter((_, index) => index % 2 !== 0)}
                    slidesToShow={5}
                />
            </Grid>
        </Grid>
    )
}
export default MeetInfluencers;
