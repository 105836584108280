import {createUseStyles} from 'react-jss';
import {Theme} from './themes/default';
import footer_bg from '../assets/images/bg2.png';

const useFooterStyles = createUseStyles((theme: Theme) => {
    return {
        container: {
            background: `url(${footer_bg})`,
            textAlign: 'center',
            marginTop: 'auto',
            backgroundSize: 'cover',

        },
        socialLinksText: {
            fontFamily: 'PoplarKozel, sanserif',
            color: theme.palette.primary.main,
            fontSize: 'max(1.5vw, 1.5vh)',
            marginTop: theme.spacing(5),
            '@media (max-width: 720px)': {
                fontSize: '2.5vh',
            }
        },
        wrapperSocialLinks:{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        socialLinks: {
            display: 'flex',
            justifyContent: 'center',
            margin: theme.spacing(2),
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '50%',
            border: '10px',
            borderStyle: 'solid',
            borderColor: theme.palette.secondary.main
        },
        socialLinksIcon: {
            width: '20px',
            height: '20px',
            // filter: 'invert(33%) sepia(66%) saturate(300%) hue-rotate(350deg) brightness(90%) contrast(88%)',
        },
        logoBig: {
            minWidth: 'calc(25vw,225px)',
            maxWidth: '225px',
            width: '100%',
            marginTop: 'max(3vw,3vh)',
        },
        aboutAlcohol:{
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(1.4vw,1.4vh)',
            color: theme.palette.primary.main,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            '& a': {
                textDecoration: 'none',
            },
            '@media (max-width: 720px)': {
                fontSize: '2vh',
            }
        },
        wrapperLegalLinks:{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
        },
        legalLink:{
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.3vw,1.3vh)',
            color: theme.palette.secondary.main,
            textDecoration: 'underline',
            fontWeight: 'bold',
            lineHeight: 1,
        },
        copyright:{
            margin: 'auto',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(5),
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '1024px',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        copyrightTypography: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.3vw,1.3vh)',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
        },
        aboutAlcoholIcon: {
          marginBottom: theme.spacing(5),
        },
    }
});

export default useFooterStyles;
