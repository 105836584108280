import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useKozelMeetStyles = createUseStyles((theme: Theme) => {
        return {
            container: {
                justifyContent: 'center',
            }
        }
    }
)

export default useKozelMeetStyles;