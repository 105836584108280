import {createUseStyles} from 'react-jss';
import {Theme} from './themes/default';

const useAgeGateStyles = createUseStyles((theme: Theme) => {
        return {
            container: {
                padding: theme.spacing(5),
                textAlign: 'center',
                backgroundColor: '#D1A85E',
                marginTop: '15vh',
                height: '75vh',
                minHeight: '495px',
            },
            circularProgress: {
                background: 'aqua',
                border: '1px solid red'
            },
            formWrapper: {
                backgroundColor: '#E3CB9E',
                padding: theme.spacing(5),
                height: '100%',
            },
            formTitle: {
                fontFamily: 'PoplarKozel, sanserif',
                color: '#413121',
                fontSize: 'max(4vh, 4vw)',
                textTransform: 'uppercase',
                lineHeight: '1.2',
                marginBottom: '5vh',
                '@media (min-width: 720px)': {
                    fontSize: '4vh',
                }
            },
            formInput: {
                fontFamily: 'PoplarKozel, sanserif',
                color: '#413121',
                fontSize: 'max(5vh,5vw)',
                lineHeight: '1',
                padding: 0,
                '& input': {
                    fontFamily: 'PoplarKozel, sanserif',
                    textAlign: 'center',
                    fontSize: 'max(5vh,5vw)',
                    padding: 0,
                    '@media (min-width: 720px)': {
                        fontSize: '5vh',
                    }
                },
                '@media (min-width: 720px)': {
                    fontSize: '5vh',
                }
            },
            formSubmit: {
                fontFamily: 'PoplarKozel, sanserif',
                color: '#413121',
                fontSize: '2vh',
                backgroundColor: '#D1A85E',
                paddingTop: '2vh',
                paddingBottom: '2vh',
                width: '100%',
                textTransform: 'uppercase',
                lineHeight: '1',
                marginTop: '3vh',
            },
            checkboxKeepIt: {
                fontFamily: theme.typography.fontFamily,
                fontSize: 'max(1.5vh,1.5vw)',
                color: '#413121',
                marginTop: '2vh',
                '& span': {
                    fontSize: 'max(1.5vh,1.5vw)',
                    '@media (min-width: 720px)': {
                        fontSize: '1.5vh',
                    }
                },
                '& .MuiCheckbox-root': {
                    margin: 0,
                },
                '@media (min-width: 720px)': {
                    fontSize: '1.5vh',
                },
            },
            textCookie: {
                fontFamily: theme.typography.fontFamily,
                fontSize: 'max(1.5vh,1.5vw)',
                color: '#413121',
                '@media (min-width: 720px)': {
                    fontSize: '1.5vh',
                }
            },
            textPolicy: {
                fontFamily: theme.typography.fontFamily,
                fontSize: 'max(1.5vh,1.5vw)',
                color: '#413121',
                '@media (min-width: 720px)': {
                    fontSize: '1.5vh',
                }
            },
            goatGif: {
                maxWidth: 'max(35vh,35vw)',
                width: '100%',
                marginTop: 'min(-25vh,-25vw)',
                maxHeight: '300px',
                '@media (min-width: 720px)': {
                    fontSize: '1.5vh',
                    marginTop: '-25vh',
                }
            },
            footer: {
                padding: theme.spacing(3),
                height: '10vh',
            },
        }
    }
);

export default useAgeGateStyles;
