import React from 'react';
import {Grid, Typography} from "@mui/material";
import Step from "../components/ncp/Step";
import useKozelNcpStyles from "../styles/ncp/kozel_ncp";
import MixTapan from "../components/ncp/MixTapan";
import FormSection from "../components/ncp/FormSection";

const KozelNcp:React.FC = () => {
    const kozelNcpStyles = useKozelNcpStyles();

    return (
        <Grid container>
            <Grid item xs={12}>
                <MixTapan />
            </Grid>
            <Grid item xs={12}>
                <Grid container className={kozelNcpStyles.stepsWrapper}>
                    <Grid item md={3} xs={12} className={kozelNcpStyles.wrapperStep}>
                        <Step index={1}>Cumpara kozel premimum & kozel dark de 25 de lei pe un singur bon fiscal</Step>
                    </Grid>
                    <Grid item md={3} xs={12} className={kozelNcpStyles.wrapperStep}>
                        <Step index={2}>Inscrie numarul de bon pe <a href="https://www.kozel.ro" rel="noreferrer" target="_blank">www.kozel.ro</a> si incarca poza bonului</Step>
                    </Grid>
                    <Grid item md={3} xs={12} className={kozelNcpStyles.wrapperStep}>
                        <Step index={3} >Poti castiga prin tragere la sorti unul dintre premiile campaniei</Step>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={kozelNcpStyles.textBeforeFormSection}>Selectează magazinul din care ai achiziționat produsele Kozel Premium și Kozel Dark și introdu bonul fiscal:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormSection />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default KozelNcp;