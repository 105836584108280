import React from "react";
import {Grid, Typography} from "@mui/material";
import useMyGroup from "../styles/my_group/myGroup";
import TeaserInviteFriends from "../components/my_group/TeaserInviteFriends";
import UntoldMixologist from "../components/my_group/UntoldMixologist";
import AmrUntold from "../components/my_group/AmrUntold";
import DidYouKnowComponent from "../components/DidYouKnowComponent";
import ListInfo from "../components/kozel/ListInfo";
import MyGroupMix from "../components/my_group/MyGroupMix";

const MyGroup: React.FC = () => {
    const myGroup = useMyGroup();
    const listInfoItems = [
        {text: 'Asigură-te că ai grupul de prieteni creat și câștigă puncte;'},
        {text: 'Mergi la Kozel Meeet și provocă-ţi prietenii;'},
        {text: 'Accesează Berea mea și descoperă KOZEL MIX;'},
        {text: 'Vizitează locaţiile Kozel cu gașca ta, pentru un MIX ţapăn de distracţie, destindere, conectare și bere ţapănă din Cehia!'},
    ]
    return (
        <Grid container className={myGroup.container}>
            <Grid item xs={12}>
                <TeaserInviteFriends/>
            </Grid>
            <Grid item xs={12}>
                <MyGroupMix />
            </Grid>
            <Grid item xs={12}>
                <UntoldMixologist/>
            </Grid>
            <Grid item xs={12}>
                <AmrUntold/>
            </Grid>
            <Grid item xs={12}>
                <Typography className={myGroup.text}>Te așteptăm cu gașca de prieteni la UNTOLD.</Typography>
                <Typography className={myGroup.text}>Fă check-in la Kozel corner @UNTOLD, participă la provocări și
                    câștigă premii și experienţe pentru grupul tău!</Typography>
            </Grid>
            <Grid item xs={12}>
                <DidYouKnowComponent title={'Ce te așteaptă aici:'}>
                    <ListInfo items={listInfoItems}/>
                </DidYouKnowComponent>
            </Grid>
        </Grid>
    )
}

export default MyGroup;