import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiInstance from '../../api/api';
import {logout} from "../auth/authSlice";
import {handleAxiosError} from "../../utils/axiosErrorHandler";

export interface PersonalDataProfileSlice {
    loading: boolean,
    firstName: string,
    lastName: string,
    birthdate: string,
    email: string,
    agreement_mk: boolean,
    agreement_sms: boolean,
    agreement_email: boolean,
    error: string,
};

const initialState: PersonalDataProfileSlice = {
    loading: false,
    firstName: '',
    lastName: '',
    birthdate: '',
    email: '',
    agreement_mk: false,
    agreement_sms: false,
    agreement_email: false,
    error: '',
}

interface ResponseError {
    message: string;
}

export const getPersonalData = createAsyncThunk(
    'contact/getPersonalData',
    async (_, thunkAPI) => {
        try {
            const response = await apiInstance.get('/personal-data');

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ResponseError);
        }
    }
);

const personalDataProfileSlice = createSlice({
    name: "profile/personalData",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getPersonalData.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(getPersonalData.fulfilled, (state, action: any) => {
                state.loading = false;
                state.firstName = action.payload.firstname;
                state.lastName = action.payload.lastname;
                state.birthdate = action.payload.birthdate;
                state.email = action.payload.email;
                state.agreement_mk = action.payload.agreement_mk;
                state.agreement_sms = action.payload.agreement_sms;
                state.agreement_email = action.payload.agreement_email;
            })
            .addCase(getPersonalData.rejected, (state, action: any) => {
                state.loading = false;
                state.error = action.payload.message;
                console.log('Eroare la acesarea datelor personale =>', action);
            })
            .addCase(logout, (state) => initialState)
        ;
    }
});

// export const {} = profileSlice.actions
export default personalDataProfileSlice.reducer;