import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../stores/store';
import {getCoins, getProfile, ProfileState, updateProfile} from '../features/profile/profileSlice';

import {Button, CircularProgress, Grid, IconButton, InputAdornment, TextField, Typography,} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import {showNotification} from "../features/notification/notificationSlice";
import {getPersonalData} from "../features/profile/personalDataProfileSlice";
import useProfileStyles from "../styles/profile";
import PersonalDataForm from "../components/PersonalDataForm";
import ModalWrapper from "../components/ModalWarpper";

interface StateErrors {
    nickName: '';
}

const Profile: React.FC = () => {
    const dispatch = useAppDispatch();
    const profileStyles = useProfileStyles();
    const profile: ProfileState = useAppSelector((state) => state.profile);
    const personalData = useAppSelector((state) => state.profilePersonalData)
    const [nickName, setNickName] = useState(profile.nick_name || '')
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [errors, setErrors] = useState<Partial<StateErrors>>({});

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getPersonalData());
        dispatch(getCoins());
    }, [dispatch]);

    useEffect(() => {
        setNickName(profile.nick_name || '');
    }, [profile.nick_name]);

    const handleNickNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNickName(event.target.value);
    };

    const handleUpdateProfile = async () => {
        try {
            await dispatch(updateProfile(nickName)).unwrap();
            dispatch(showNotification({message: 'Profile updated successfully', variant: 'success'}));
        } catch (error) {
            dispatch(showNotification({message: 'Failed to update profile', variant: 'error'}));
        }
    };

    const hasProfileCompleted = () => {
        if (personalData && personalData.agreement_email) {
            return true;
        }
        return false;
    }

    if (profile.loading) {
        return <CircularProgress/>;
    }

    return (
        <Grid container className={profileStyles.container} flexDirection={'column'}>
            <Grid item xs={12} lg={6} xl={4} className={profileStyles.formContainer}>
                <Typography className={profileStyles.title}>
                    Profil
                </Typography>
                <TextField
                    label="Nick name"
                    fullWidth
                    margin="normal"
                    value={nickName}
                    className={profileStyles.formInput}
                    onChange={handleNickNameChange}
                    error={!!errors.nickName}
                    helperText={errors.nickName}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    color="primary"
                                    onClick={handleUpdateProfile}
                                    disabled={profile.loading}
                                >
                                    <SaveIcon/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                {!hasProfileCompleted &&
                    <Typography className={profileStyles.textHaveAccount}>Completează-ti datele personale pentru a
                        caștiga
                        50 de puncte și a putea să îți comunicăm viitoarele campanii, oferte, premii, jocuri și multe
                        altele</Typography>
                }
                <Button className={profileStyles.submitButton} onClick={() => {
                    setModalIsOpen(true)
                }}>{hasProfileCompleted() ? 'Editează date personale' : 'Completează date personale'}</Button>
            </Grid>
            <ModalWrapper isOpen={modalIsOpen} onRequestClose={() => {
                setModalIsOpen(false)
            }}><PersonalDataForm onRequestClose={() => {
                setModalIsOpen(false)
            }}/></ModalWrapper>
        </Grid>
    );
};

export default Profile;
