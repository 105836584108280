import React from 'react';
import KozelNcp from "./KozelNcp";

const Home: React.FC = () => {
    return (
        <KozelNcp />
    );
};

export default Home;
