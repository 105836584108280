import React, {useState} from 'react';
import useFormSectionStyles from "../../styles/ncp/form_section";
import {Button, Grid, Typography} from "@mui/material";
import ImageComponent from "../../utils/ImageComponent";
import logo_carrefour_dark from '../../assets/images/ncp/logo_carrefour.png';
import logo_carrefour_light from '../../assets/images/ncp/logo_carrefour_light.png';
import logo_auchan_dark from '../../assets/images/ncp/logo_auchan.png';
import logo_auchan_light from '../../assets/images/ncp/logo_auchan_light.png';
import logo_mega_dark from '../../assets/images/ncp/logo_mega.png';
import logo_mega_light from '../../assets/images/ncp/logo_mega_light.png';
import logo_kaufland_dark from '../../assets/images/ncp/logo_kaufland.png';
import logo_kaufland_light from '../../assets/images/ncp/logo_kaufland_light.png';
import logo_profi_dark from '../../assets/images/ncp/logo_profi.png';
import logo_profi_light from '../../assets/images/ncp/logo_profi_light.png';
import logo_penny_dark from '../../assets/images/ncp/logo_penny.png';
import logo_penny_light from '../../assets/images/ncp/logo_penny_light.png';
import img_card_penny from '../../assets/images/card-penny.png';

import digit_1 from '../../assets/images/digit_1.png';
import digit_2 from '../../assets/images/digit_2.png';
import digit_3 from '../../assets/images/digit_3.png';
import clsx from "clsx";
import FormNcp from "./FormNcp";

export const partners = {
    carrefour: 'carrefour',
    auchan: 'auchan',
    kaufland: 'kaufland',
    mega: 'mega',
    penny: 'penny',
    profi: 'profi',
}
export const regulamenteParteneri = {
  [partners.carrefour]: '10-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Carrefour.pdf',
  [partners.auchan]: '11-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Auchan.pdf',
  [partners.kaufland]: '12-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Kaufland.pdf',
  [partners.mega]: '13-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Mega Image.pdf',
  [partners.penny]: '14-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival” - Penny.pdf',
  [partners.profi]: '15-Regulamentul oficial al Campaniei Kozel “Mix Tapan de festival“ - Profi.pdf',
};
const messageTitleByPartner = {
    [partners.carrefour]: 'Kozel și Carrefour te premiază!',
    [partners.auchan]: 'Kozel și Auchan te premiază!',
    [partners.kaufland]: 'Kozel și Kaufland te premiază!',
    [partners.mega]: 'Kozel și Mega-Image te premiază!',
    [partners.penny]: 'Kozel și Penny Market te premiază!',
    [partners.profi]: 'Kozel și Profi te premiază!',
}
const FormSection: React.FC = () => {
    const formSectionStyle = useFormSectionStyles();
    const [partner, setPartner] = useState(partners.carrefour);

    const handleChangePartner = (partnerNAme: string) => {
        setPartner(partnerNAme)
    }


    const ContentStep2Default = () => (
        <Grid item xs={12} className={formSectionStyle.prizeStep}>
            <Typography className={clsx(formSectionStyle.prizeStepText, formSectionStyle.textRight)}>Înscrie numărul de
                bon, completează formularul, încarcă poza bonului și intri automat în tragerea la sorți pentru unul
                dintre premiile campaniei!</Typography>
            <img src={digit_2} alt={'digit_2'}
                 className={clsx(formSectionStyle.digitImg, formSectionStyle.digitImgRight)}/>
        </Grid>
    );

    const ContentStep2Penny = () => (
        <Grid item xs={12} className={formSectionStyle.prizeStep}>
            <Typography className={clsx(formSectionStyle.prizeStepText)}>Folosește cardul PENNY <br/>în magazin și participă automat la campanie</Typography>
            <img src={digit_2} alt={'digit_2'} className={clsx(formSectionStyle.digitImg)}/>
            <img src={img_card_penny} alt={'img_card_penny'} className={formSectionStyle.step2PennyCard}/>
        </Grid>
    );
    return (
        <Grid container>
            <Grid item xs={12} className={formSectionStyle.partnerSelectionBox}>
                <Grid container justifyContent={'space-around'}>
                    <Grid item xs={2}
                          className={`${formSectionStyle.partnerSelection} ${partner === partners.carrefour ? 'active' : ''} `}>
                        <Button onClick={e => handleChangePartner(partners.carrefour)}>
                            {partner === partners.carrefour ?
                                <ImageComponent src={logo_carrefour_light} alt={'logo_carrefour_light'}/> :
                                <ImageComponent src={logo_carrefour_dark} alt={'logo_carrefour_dark'}/>}
                        </Button>
                    </Grid>
                    <Grid item xs={2}
                          className={`${formSectionStyle.partnerSelection} ${partner === partners.auchan ? 'active' : ''} `}>
                        <Button onClick={e => handleChangePartner(partners.auchan)}>
                            {partner === partners.auchan ?
                                <ImageComponent src={logo_auchan_light} alt={'logo_auchan_light'}/> :
                                <ImageComponent src={logo_auchan_dark} alt={'logo_auchan_dark'}/>}
                        </Button>
                    </Grid>
                    <Grid item xs={2}
                          className={`${formSectionStyle.partnerSelection} ${partner === partners.kaufland ? 'active' : ''} `}>
                        <Button onClick={e => handleChangePartner(partners.kaufland)}>
                            {partner === partners.kaufland ?
                                <ImageComponent src={logo_kaufland_light} alt={'logo_kaufland_light'}/> :
                                <ImageComponent src={logo_kaufland_dark} alt={'logo_kaufland_dark'}/>}
                        </Button>
                    </Grid>
                    <Grid item xs={2}
                          className={`${formSectionStyle.partnerSelection} ${partner === partners.mega ? 'active' : ''} `}>
                        <Button onClick={e => handleChangePartner(partners.mega)}>
                            {partner === partners.mega ?
                                <ImageComponent src={logo_mega_light} alt={'logo_mega_light'}/> :
                                <ImageComponent src={logo_mega_dark} alt={'logo_mega_dark'}/>}
                        </Button>
                    </Grid>
                    <Grid item xs={2}
                          className={`${formSectionStyle.partnerSelection} ${partner === partners.penny ? 'active' : ''} `}>
                        <Button onClick={e => handleChangePartner(partners.penny)}>
                            {partner === partners.penny ?
                                <ImageComponent src={logo_penny_light} alt={'logo_penny_light'}/> :
                                <ImageComponent src={logo_penny_dark} alt={'logo_penny_dark'}/>}
                        </Button>
                    </Grid>
                    <Grid item xs={2}
                          className={`${formSectionStyle.partnerSelection} ${partner === partners.profi ? 'active' : ''} `}>
                        <Button onClick={e => handleChangePartner(partners.profi)}>
                            {partner === partners.profi ?
                                <ImageComponent src={logo_profi_light} alt={'logo_lidl_light'}/> :
                                <ImageComponent src={logo_profi_dark} alt={'logo_lidl_dark'}/>}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={formSectionStyle.bgWoodDark}>
                <Grid container>
                    <Grid item xs={12} className={formSectionStyle.titlePromotionBox}>
                        <Typography
                            className={formSectionStyle.titlePromotion}>{messageTitleByPartner[partner]}</Typography>
                        <Typography className={formSectionStyle.subTitlePromotion}>1 iulie - 31 iulie 2024</Typography>
                    </Grid>
                    <Grid item xs={12} className={formSectionStyle.prizeStep}>
                        <img src={digit_1} alt={'digit_1'} className={formSectionStyle.digitImg}/>
                        <Typography className={formSectionStyle.prizeStepText}>Cumpară Kozel Premium sau Kozel Dark în
                            orice combinație, de minim 25 lei pe un singur bon fiscal.</Typography>
                    </Grid>
                    { partner === partners.penny ? <ContentStep2Penny /> : <ContentStep2Default/>}
                    <Grid item xs={12} className={formSectionStyle.prizeStep}>
                        <img src={digit_3} alt={'digit_3'} className={formSectionStyle.digitImg}/>
                        <Typography className={formSectionStyle.prizeStepText}>Păstrează bonul fiscal pentru validarea
                            premiului în cazul în care vei fi declarat potențial câștigător.</Typography>
                    </Grid>
                </Grid>
                <Grid container className={formSectionStyle.containerFormNcp}>
                    <Grid item md={8} xs={12} className={formSectionStyle.itemFormNcp}>
                        <FormNcp partner={partner}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className={formSectionStyle.aboutPromotionSection}>
                        <Typography className={formSectionStyle.aboutPromotionText}>Durata
                            promoției: <b>01.07.2024-31.07.2024</b>.
                            Consultă gratuit regulamentul promoției <a href={`/pdf/2024/${regulamenteParteneri[partner]}`} target="_blank" style={{ textDecoration: 'underline'}}>aici</a>.</Typography>
                        <Typography className={formSectionStyle.aboutPromotionText}>Numărul total de premii este de 950,
                            cu o valoare totală netă comercială estimativă
                            de 398.643,46 lei cu tva inclus, premiile se acordă prin tragere la sorti.
                            Lista locațiilor participante poate fi consultată in regulamentul companiei</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FormSection;