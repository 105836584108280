import {createUseStyles} from "react-jss";
import {Theme} from "./themes/default";

const useVideoPlayerStyles = createUseStyles((theme: Theme) => {
    return {
        container: {
            position: 'relative', width: 'auto', height: '75vh'
        },
        video: {
            width: 'auto', height: '100%'
        },
        playButton: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            borderRadius: '50%',
            width: '64px',
            height: '64px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            '& img':{
                width: '100%',
                height: '100%',
                display: 'block',
            }
        }
    }
});

export default useVideoPlayerStyles;