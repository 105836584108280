import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Campaign from "./pages/Campaign";
import Profile from "./pages/Profile";
import BereaMea from "./pages/BereaMea";
import KozelLocations from "./pages/KozelLocations";
import OurBeers from "./pages/OurBeers";
import OurTradition from "./pages/OurTradition";
import ServingRitual from "./pages/ServingRitual";
import Regulamente from "./pages/Regulamente";
import KozelMeet from "./pages/KozelMeet";
import RegisterPage from "./pages/RegisterPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import RedirectJoinGroupComponent from "./components/RedirectJoinGroupCOmponent";
import MyGroup from "./pages/MyGroup";

interface AppRoutersProps {
    isAuthenticated: boolean
}
const AppRouters: React.FC<AppRoutersProps> = ({ isAuthenticated }) => {

    return <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/campaign" element={<Campaign/>}/>
        <Route path="/berea-mea" element={<BereaMea/>}/>
        <Route path="/locatii" element={<KozelLocations/>}/>
        <Route path="/berile-noastre" element={<OurBeers/>} />
        <Route path="/traditia-noastra" element={<OurTradition/>} />
        <Route path="/ritualul-de-servire" element={<ServingRitual/>} />
        <Route path="/regulamente" element={<Regulamente/>} />
        <Route path='/kozel-meet' element={<KozelMeet/>} />
        <Route path='/referal-code' element={<RedirectJoinGroupComponent />} />
        <Route path='/grupul-meu' element={<MyGroup />} />
        {isAuthenticated ? (
            <Route path="/profile" element={<Profile/>}/>
        ) : (
            <>
                <Route path="/register" element={<RegisterPage/>}/>
                <Route path="/forget-password" element={<ForgetPasswordPage/>}/>
                <Route path="/profile" element={<Navigate to="/" replace />}/>
            </>
        )}
        <Route path="*" element={<Navigate to="/"/>}/>
    </Routes>;
}

export default AppRouters;