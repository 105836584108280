import React, {useState} from 'react';
import {AppBar, Button, Drawer, Grid, IconButton, List, ListItem, ListItemText, Toolbar} from '@mui/material';
import {NavLink} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from "../stores/store";
import {logout, mockLogin, openLoginModal} from "../features/auth/authSlice";
import logoPng from '../assets/images/logo.png';
import burger_icon from '../assets/images/burger_icon.png';
import useNavbarStyles from "../styles/navbar";

const Navbar: React.FC = () => {
    const dispatch = useAppDispatch();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
    const navbarStyle = useNavbarStyles();

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };
    const payloadMOckLogin = {
        isAuthenticated: true,
        accessToken: '1234',
        refreshToken: '1234',
        error: '',
        loading: false,
        isLoginModalOpen: false,
        isRegisterModalOpen: false,
        isPersonalDataModalOpen: false,
        hasIdentity: true,
    };

    const handleSwitchToLogged = () => {
        dispatch(mockLogin(payloadMOckLogin))
    }

    const menuItems = [
        {text: 'Home', path: '/'},
        {text: 'Kozel Meet', path: '/kozel-meet'},
        {text: 'Berea Mea', path: '/berea-mea'},
        {text: 'Berile Noastre', path: '/berile-noastre'},
        {text: 'Tradiția Noastră', path: '/traditia-noastra'},
        {text: 'Ritualul de Servire', path: '/ritualul-de-servire'},
        {text: 'Regulamente', path: '/regulamente'},
    ];

    if (isAuthenticated) {
        menuItems.push({text: 'Profil', path: '/profile'})
        menuItems.push({text: 'Grupul meu', path: '/grupul-meu'})
    }

    const handleLogout = () => {
        dispatch(logout());
    }

    const handleLogin = () => {
        dispatch(openLoginModal());
    }

    return (
        <>
            <AppBar position="sticky" className={navbarStyle.appBar}>
                <Toolbar className={navbarStyle.toolbar}>
                    <NavLink to={'/'}><img src={logoPng} alt={'Kozel'} className={navbarStyle.logo}/></NavLink>
                    <IconButton edge="start" color="inherit" aria-label="menu"
                                onClick={toggleDrawer(true)}
                                className={navbarStyle.burgerButton}
                    >
                        <img src={burger_icon} alt={'Burger Icon'}
                             className={navbarStyle.burgerIcon}
                        />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)} className={navbarStyle.navbarDrawer}>
                <Grid container>
                    {isAuthenticated && <Grid item xs={6}><Button className={navbarStyle.button} onClick={e => {
                        setDrawerOpen(false);
                        handleLogout();
                    }}>Logout</Button></Grid>}
                    {isAuthenticated && <Grid item xs={6}><NavLink to={'/profile'} onClick={e => {
                        setDrawerOpen(false)
                    }}><Button className={navbarStyle.button}>Profil</Button></NavLink></Grid>}
                    {!isAuthenticated &&
                        <Grid item xs={6}><Button className={navbarStyle.button}
                                                  onClick={e => {
                                                      setDrawerOpen(false);
                                                      handleSwitchToLogged();
                                                      handleLogin();
                                                  }}>Login</Button></Grid>}
                    {!isAuthenticated &&
                        <Grid item xs={6}>
                            <NavLink to={'/register'} onClick={e => {
                                setDrawerOpen(false)
                            }}><Button className={navbarStyle.button}>Register</Button></NavLink>
                        </Grid>}
                </Grid>
                <List className={navbarStyle.navbarDrawerList}>
                    {menuItems.map((item) => (
                        <ListItem className={navbarStyle.navbarDrawerListItem} component={NavLink} to={item.path}
                                  key={item.text} onClick={toggleDrawer(false)}>
                            <ListItemText primary={item.text} className={navbarStyle.navbarDrawerListItemText}/>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </>
    );
};

export default Navbar;
