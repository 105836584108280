import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useAmrUntold = createUseStyles((theme: Theme) => {
    return {
        wrapperContainer: {
            padding: `${theme.spacing(5)} ${theme.spacing(10)}`,
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(5)} ${theme.spacing(2)}`,
            }
        },
        container: {
            borderWidth: '15px',
            borderRadius: '5vw',
            borderStyle: 'dotted',
            borderColor: theme.colors.desert_sand_gold,
            padding: `${theme.spacing(5)} ${theme.spacing(10)}`,
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(5)} ${theme.spacing(2)}`,
                borderRadius: '3vw',
            }
        },
        containerItem: {
            display: 'flex',
            justifyContent: "center",
            alignItems: 'center',
            marginBottom: theme.spacing(5),
            position: 'relative',
            '&:last-child': {
                marginBottom: theme.spacing(0),
            }
        },
        dottedLineBackground: {
            content: 'test',
            position: 'absolute',
            top: '50%',
            left: '0',
            right: '0',
            height: '0',
            borderTopWidth: '15px',
            borderTopStyle: 'dotted',
            borderTopColor: theme.colors.desert_sand_gold,
            zIndex: 0,
        },
        textAmr: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(5vh,5vw)',
            color: theme.colors.latte_cream,
            textTransform: 'uppercase',
            fontWeight: 900,
        },
        textNrDays: {
            position: "relative",
            fontFamily: theme.fonts.neonLight,
            fontSize: 'max(10vh,10vw)',
            color: theme.colors.latte_cream,
            zIndex: 100,
            background: theme.colors.dark_chocolate,
            padding: `0 ${theme.spacing(15)}`,
        },
        textDay: {
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(5vh,5vw)',
            color: theme.colors.latte_cream,
            textTransform: 'uppercase',
            fontWeight: 900,
        }
    };
});

export default useAmrUntold;