import React, {useEffect, useState} from 'react';
import ReactDOMServer from 'react-dom/server';
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import PushPinIcon from '@mui/icons-material/PushPin';

const containerStyle = {
    width: '100vw',
    height: '90vh',
};

const center = {
    lat: 46,
    lng: 25.5,
};
const createSVGIconURL = (IconComponent: React.ElementType, color = "red", size = 40) => {
    const iconSVGString = ReactDOMServer.renderToStaticMarkup(
        <IconComponent style={{color, fontSize: size}}/>
    );
    const svgBlob = new Blob([iconSVGString], {type: 'image/svg+xml'});
    return URL.createObjectURL(svgBlob);
};

const customIconUrl = createSVGIconURL(PushPinIcon);

interface Location {
    name: string,
    lat: number,
    lng: number,
    zIndex: number,
}

const locations: Location[] = [
    {name: "Acasa la Silviu", lat: 44.85910106625311, lng: 25.492118716675893, zIndex: 1},
    {name: "Acasa la Silviu2", lat: 45.85910106625311, lng: 26.492118716675893, zIndex: 2},
    {name: "Acasa la Silviu3", lat: 43.85910106625311, lng: 24.492118716675893, zIndex: 3},
];

const KozelLocations: React.FC = () => {
    const [mapLoaded, setMapLoaded] = useState(false);

    useEffect(() => {
        if (window.google) {
            setMapLoaded(true);
        }
    }, []);
    console.log('mapLoaded => ', mapLoaded);
    return (
        <LoadScript
            googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAP_API}`}
            onLoad={() => setMapLoaded(true)}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={8}
            >
                {mapLoaded && locations.map((item: Location, index: number) => {
                    console.log('Render marker => ', item, index);
                    return (
                        <Marker
                            key={index}
                            position={{lat: item.lat, lng: item.lng}}
                            label={item.name}
                            zIndex={item.zIndex}
                            icon={{
                                url: customIconUrl,
                                scaledSize: new window.google.maps.Size(100, 100),
                            }}
                        />
                    )
                })}
            </GoogleMap>
        </LoadScript>
    );
};

export default KozelLocations;
