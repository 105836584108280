import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiInstance from '../../api/api';

interface MyGroupMember {
    id: number | null,
    nick_name: string | null
}

interface MyGroupState {
    id: number | null,
    name: string | null,
    members: [MyGroupMember]|null,
    loading: boolean,
}

const initialState: MyGroupState = {
    id: null,
    name: null,
    members: null,
    loading: false,
}

export const getMyGroup = createAsyncThunk(
    'profile/my_group',
    async () => {
        const response = await apiInstance.get('/my_group');

        return response.data;
    }
);

const myGroupSlice = createSlice({
    name: "profile/my_group",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getMyGroup.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getMyGroup.fulfilled, (state, action) => {
                state.loading = false;
                state.id = action.payload.id
                state.name = action.payload.name
                state.members = action.payload.members
            })
            .addCase(getMyGroup.rejected, (state, action) => {
                state.loading = false;
                console.log(' hase some errors => ', state, action)
            })
        ;
    }
});

// export const {} = profileSlice.actions
export default myGroupSlice.reducer;