import {createUseStyles} from "react-jss";
import {Theme} from "./themes/default";

const useTeaserFrame2 = createUseStyles((theme: Theme) => {
    return {
        container: {
            borderWidth: '10px',
            borderStyle: 'dotted',
            borderColor: theme.colors.desert_sand_gold,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: '5vw',
        },
        containerItem: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
        },
        boxTitle: {
            display: "inline-block",
            padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
            backgroundSize: 'cover',
        }
    }
});

export default useTeaserFrame2;