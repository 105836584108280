import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import apiInstance from "../../api/api";
import axios from "axios";
import {RejectedWithValueAction, handleAxiosError, ResponseError} from "../../utils/axiosErrorHandler";

interface ForgetPasswordState {
    loading: boolean;
    isCodeSent: boolean;
    isResetPasswordSuccess: boolean;
    error: string;
}

interface ResponseForgetPasswordSendSms {
    success: boolean;
    message?: string;
}

export interface SendSmsPayload {
    phone: string;
}

export interface ResetPasswordPayload {
    phone: string;
    code: string;
    password: string;
    confirmPassword: string;
}

const initialState: ForgetPasswordState = {
    loading: false,
    isCodeSent: false,
    isResetPasswordSuccess: false,
    error: '',
}

export const sendPhoneCode = createAsyncThunk(
    'forgetPassword/sensSms',
    async (payload: SendSmsPayload, thunkAPI) => {
        try {
            const response = await apiInstance.post<ResponseForgetPasswordSendSms>('/forget-password/send-code', payload);

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ResponseError);
        }
    }
)

export const resetPassword = createAsyncThunk(
    'forgetPassword/resetPassword',
    async (payload: ResetPasswordPayload, thunkAPI) => {
        try {
            const response = await apiInstance.post<ResponseForgetPasswordSendSms>('/forget-password/reset_password', payload);

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(handleAxiosError(error) as ResponseError);
        }
    }
)

const forgetPasswordSlice = createSlice({
    name: 'forgetPassword',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(sendPhoneCode.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendPhoneCode.fulfilled,(state)=> {
                state.loading = false;
                state.isCodeSent = true;
            })
            .addCase(sendPhoneCode.rejected, (state, action: any) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(resetPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.loading = false;
                state.isResetPasswordSuccess = true;
            })
            .addCase(resetPassword.rejected, (state,action: any) => {
                state.loading = false;
                state.error = action.payload.message;
            })
        ;

    }
});

export const {

} = forgetPasswordSlice.actions
export default forgetPasswordSlice.reducer;