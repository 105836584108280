import { createUseStyles } from 'react-jss';
import theme from "./themes/default";

const useCookiesStyles = createUseStyles({
    consentBanner: {
        background: `${theme.palette.background.default} !important`,
        color: `${theme.palette.primary.main} !important`,
        fontFamily: theme.fonts.apex,
        fontSize: 'max(1vh,1vw) !important',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        left: 0,
        position: 'fixed',
        width: '100%',
        zIndex: 999,
        bottom: 0,
    },
    consentText: {
        fontFamily: theme.fonts.apex,
        flex: '1 0 300px',
        margin: '15px',
    },
    consentButtonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    consentButton: {
        background: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.secondary.main} !important`,
        fontFamily: theme.fonts.apex,
        fontSize: 'max(1vh,1vw) !important',
        border: 0,
        borderRadius: 0,
        boxShadow: 'none',
        cursor: 'pointer',
        flex: '0 0 auto',
        padding: `${theme.spacing(2)} !important`,
        margin: '15px',
        '&:hover': {
            color: `${theme.palette.primary.light} !important`,
        }
    },
    declineButton: {
        background: `${theme.palette.primary.main} !important`,
        border: 0,
        borderRadius: 0,
        boxShadow: 'none',
        color: 'white',
        cursor: 'pointer',
        flex: '0 0 auto',
        padding: '5px 10px',
        margin: '15px',
    }
});

export default useCookiesStyles;
