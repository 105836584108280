import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';

const useKozelNcpStyles = createUseStyles((theme: Theme) => {
    return {
        stepsWrapper: {
            justifyContent: 'space-around',
            marginTop: theme.spacing(5),
            paddingLeft: 'max(10vw,10vh)',
            paddingRight: 'max(10vw,10vh)',
            [theme.breakpoints.down('md')]: {
                paddingLeft: 'max(2vw,2vh)',
                paddingRight: 'max(2vw,2vh)',
            }
        },
        wrapperStep:{
            [theme.breakpoints.down('md')]:{
                marginTop: theme.spacing(5),
                marginRight: theme.spacing(2),
            }
        },
        textBeforeFormSection: {
            fontFamily: 'PoplarKozel, san-serif',
            fontSize: 'max(3vw,3vh)',
            fontWeight: 'bold',
            padding: `max(10vw,10vh) max(10vw,10vh) max(2vw,2vh) max(10vw,10vh)`,
            textAlign: "center",
            [theme.breakpoints.down('md')]: {
                padding: `max(10vw,10vh) max(2vw,2vh) max(2vw,2vh) max(2vw,2vh)`,
                letterSpacing: '1px',
                fontSize: 'max(2.5vw,2.5vh)',
                fontWeight: 'normal',
            },
        }
    }
});

export default useKozelNcpStyles;
