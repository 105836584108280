import {createUseStyles} from "react-jss";
import {Theme} from "../themes/default";

const useTeaserTeamAndGame = createUseStyles((theme: Theme) => {
    return {
        wrapperContainer: {
            backgroundColor: theme.colors.dark_chocolate,
        },
        container: {
            justifyContent: 'center',
            padding: `0 ${theme.spacing(10)}`,
            margin: theme.spacing(10),
            borderWidth: '10px',
            borderStyle: "dotted",
            borderColor: theme.colors.desert_sand_gold,
            borderRadius: '25px',
            [theme.breakpoints.down('lg')]: {
                borderWidth: '8px',
                padding: `0 ${theme.spacing(3)}`,
                margin: theme.spacing(2),
            }
        },
        containerItem: {
            padding: theme.spacing(10),
            [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(2),
            }
        },
        containerItemSeparator: {
            borderTopWidth: '10px',
            borderTopStyle: "dotted",
            borderTopColor: theme.colors.desert_sand_gold,
        },

        sectionContainer: {
            justifyContent: 'space-between',
            alignItems: "center",
        },
        sectionContainerItem: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            alignItems: "center",
        },
        sectionContainerItemPaddingRight: {
            paddingRight: theme.spacing(3),
            [theme.breakpoints.down('lg')]: {
                paddingBottom: theme.spacing(2),
                paddingRight: theme.spacing(0),
            }
        },
        sectionText: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vw,1.5vh)',
            color: theme.colors.desert_sand_gold,
        },
        sectionButton: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vw,1.5vh)',
            backgroundColor: theme.colors.desert_sand_gold,
            color: theme.colors.dark_chocolate,
            padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
            marginTop: theme.spacing(3),
            fontWeight: 900,
            '&:hover': {
                color: theme.colors.desert_sand_gold,
                backgroundColor: theme.colors.dark_chocolate,
            },
            [theme.breakpoints.down('lg')]: {
                padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
            }
        },
        sectionTextBig: {
            fontFamily: theme.fonts.apex,
            fontSize: 'max(1.5vw,1.5vh)',
            color: theme.colors.desert_sand_gold,
            fontWeight: 900,
            marginTop: theme.spacing(3),
            textTransform: 'uppercase',
        },
    }
});

export default useTeaserTeamAndGame;