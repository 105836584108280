import React, {useState} from 'react';
import {useAppDispatch, useAppSelector} from '../stores/store';
import {closeLoginModal, login} from '../features/auth/authSlice';
import {Box, Button, CircularProgress, FormControlLabel, Modal, Switch, TextField, Typography} from '@mui/material';
import useLoginModalStyles from "../styles/loginModal";
import {NavLink} from "react-router-dom";

const phoneNumberValidationRule = new RegExp('^(07)[0-9]{8}$', 'i');

interface LoginModalProps {
    onRequestClose: () => void;
    onLoginSuccess: () => void;
}

interface StateErrors {
    phone: string,
    password: string,
}

const LoginModal: React.FC<LoginModalProps> = ({ onRequestClose, onLoginSuccess }) => {
    const dispatch = useAppDispatch();
    const loginModalStyles = useLoginModalStyles();
    const {error, loading, isLoginModalOpen} = useAppSelector((state) => state.auth);
    const localStorageKeepLogin = localStorage.getItem('keep_logged_in') === 'true';
    const [phoneInput, setPhoneInput] = useState('');
    const [password, setPassword] = useState('');
    const [keepLoggedIn, setKeepLoggedIn] = useState(localStorageKeepLogin);
    const [errors, setErrors] = useState<StateErrors>({phone: '', password: ''});

    const validateHandleLogin = () => {
        validatePhone(phoneInput);
        validatePassword(password);
    }

    const validatePhone = (phone: string) => {
        if (!phoneNumberValidationRule.test(phone)) {
            console.log('se valideza telefonul=> ',phone);
            setErrors(prevState => ({
                ...prevState,
                phone: 'Numarul de telefon trebuie sa inceapa cu 07 si sa fie de 10 cifre.'
            }));

            return false;
        }

        resetErrorPhone();

        return true;
    }

    const resetErrorPhone = () => {
        setErrors(prevState => ({
            ...prevState,
            phone: ''
        }));
    }

    const validatePassword = (pass: string) => {
        if (pass.length < 8) {
            setErrors(prevState => ({
                ...prevState,
                password: 'Parola trebuie sa fie de minim 8 caractere.'
            }));

            return false;
        }

        resetErrorPassword();
        return true;
    };

    const resetErrorPassword = () => {
        setErrors(prevState => ({
            ...prevState,
            password: ''
        }));
    }
    const handleLogin = () => {
        validateHandleLogin();

        if (Object.values(errors).every(currVal => currVal === '')) {
            dispatch(login({phone: phoneInput, password, keepLoggedIn}));
        }
    };
    const handleSetPhoneInput = (value: string) => {
        resetErrorPhone();
        setPhoneInput(value.replace(/[^\d.]/g, ''));
    }
    const handleSetPassword = (value: string) => {
        resetErrorPassword();
        setPassword(value);
    }

    return (
        <Modal
            open={isLoginModalOpen}
            onClose={onRequestClose}
            aria-labelledby="login-modal-title"
            aria-describedby="login-modal-description"
        >
            <Box className={loginModalStyles.box}>
                <Typography id="login-modal-title" className={loginModalStyles.title}>
                    Login
                </Typography>
                {error && <Typography color="error">{error}</Typography>}
                <TextField
                    label="Telefon"
                    fullWidth
                    className={loginModalStyles.formInput}
                    margin="normal"
                    value={phoneInput}
                    onChange={(e) => handleSetPhoneInput(e.target.value)}
                    error={!!errors.phone}
                    helperText={errors.phone}
                />
                <TextField
                    type={'password'}
                    label="Parola"
                    fullWidth
                    className={loginModalStyles.formInput}
                    margin="normal"
                    value={password}
                    onChange={(e) => handleSetPassword(e.target.value)}
                    error={!!errors.password}
                    helperText={errors.password}
                />
                <FormControlLabel
                    className={loginModalStyles.formControlCheckbox}
                    control={
                        <Switch
                            checked={keepLoggedIn}
                            onChange={(e) => setKeepLoggedIn(e.target.checked)}
                            inputProps={{'aria-label': 'controlled'}}
                        />
                    }
                    labelPlacement={'start'}
                    label={<Typography className={loginModalStyles.formControlCheckboxLabel}>Raman logat</Typography>}
                />
                <Button onClick={handleLogin} disabled={loading} className={loginModalStyles.submitButton}>
                    Autentificare {loading ? <CircularProgress size={24}/> : ''}
                </Button>
                <Typography className={loginModalStyles.textGoToForgetPassword}><NavLink to={'/forget-password'} onClick={(e) => {dispatch(closeLoginModal())}}>Ți-ai uitat parola?</NavLink></Typography>
                <Typography className={loginModalStyles.textGoToRegister}>Nu ai cont? <NavLink to={'/register'} onClick={(e) => {dispatch(closeLoginModal())}}>Înregistrează-te</NavLink></Typography>
            </Box>
        </Modal>
    );
};

export default LoginModal;
