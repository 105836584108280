import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';
import imgWood from '../../assets/images/ncp/ncp_pasi_1.png';

const useStepStyles = createUseStyles((theme: Theme) => {
    return {
        box: {
            display: 'flex',
            alignItems: 'stretch',
            position: 'relative',
            backgroundImage: `url(${imgWood})`,
            backgroundSize: 'contain',
            backgroundPositionX: 'right',
            backgroundRepeat: 'no-repeat',
            minHeight: '255px',
            height: '100%',
        },
        index: {
            position: 'absolute',
            top: '-15px',
            right: '-15px',
            padding: theme.spacing(2),
            fontFamily: theme.fonts.apex,
            lineHeight: 1.2,
            fontSize: '28px',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            borderRadius: '50%',
            width: '25px',
            height: '25px',
            textAlign: 'center',
            boxShadow: '1px 1px 4px 1px rgba(0,0,0,0.6)',
            [theme.breakpoints.only('xs')]: {
                fontSize: 'max(4vw,4vh)',
            },
            [theme.breakpoints.only('sm')]: {
                fontSize: 'max(4vw,4vh)',
            },
            [theme.breakpoints.only('md')]: {

            },
            [theme.breakpoints.only('lg')]: {

            },
            [theme.breakpoints.only('xl')]: {

            }
        },
        textFrame: {
            display: 'flex',
            backgroundColor: theme.palette.background.default,
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: '25px',
            margin: `${theme.spacing(9)} ${theme.spacing(3)} ${theme.spacing(5)} ${theme.spacing(5)}`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            height: `calc(100% - ${theme.spacing(14)})`,
            width: '100%',
            opacity: 0,
            transform: 'translateX(-10vw) translateY(0vw)',
            '&.visible': {
                opacity: 1,
                transform: 'translateX(0vw) translateY(0vw)',
                transition: 'transform 1.5s ease-in-out',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.light,
                '& typography': {
                    color: theme.palette.background.default,
                    '& a': {
                        color: `${theme.palette.primary.light}`,
                    }
                }
            },
            [theme.breakpoints.down('sm')]: {

            }
        },
        typography: {
            fontFamily: theme.fonts.apex,
            fontSize: '28px',
            fontWeight: '600',
            textTransform: 'uppercase',
            color: theme.palette.secondary.main,
            padding: theme.spacing(4),
            width: '100%',
            height: 'auto',
            textAlign: 'center',
            '& a': {
                textDecoration: 'none',
                color: theme.palette.primary.dark,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '24px'
            }
        }
    }
});

export default useStepStyles;
