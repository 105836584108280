import React from "react";
import {Grid, Typography} from "@mui/material";
import useWhatNext from "../../styles/kozelMeet/whatNext";
import TeaserFrame2 from "../TeaserFrame2";
import {NavLink} from "react-router-dom";

import theme from '../../styles/themes/default';
const WhatNext: React.FC = () => {
    const whatNext = useWhatNext();

    return (
        <Grid container className={whatNext.container}>
            <Grid item xs={12} className={whatNext.containerItem}>
                <Typography className={whatNext.message}>Ei și-au făcut echipele.</Typography>
            </Grid>
            <Grid item xs={12} className={whatNext.containerTeaser}>
                <TeaserFrame2 titleMarginTop={"min(-3vw,-3vh)"} backgroundBoxTitle={theme.colors.dark_chocolate} title={<Typography className={whatNext.teaserTitle}>Ce urmează?</Typography>}>
                <Grid container className={whatNext.teaserContentContainer}>
                    <Grid item xs={12} className={whatNext.teaserContentContainerItem}>
                        <Typography className={whatNext.teaserContentText1}>Hai în comunitatea Kozel Meeet, creează-ți echipa care te însoțește la Untold și participă la prima provocare lansată de Kozel!</Typography>
                    </Grid>
                    <Grid item xs={12} className={whatNext.teaserContentContainerItem}>
                        <Typography className={whatNext.teaserContentText2}>Garantăm distracție și puncte pentru tine și echipa ta.</Typography>
                    </Grid>
                    <Grid item xs={12} className={whatNext.teaserContentContainerItem}>
                        <Typography className={whatNext.teaserContentText2}>Creează-ți cont pe kozel.ro și descoperă provocarea!</Typography>
                    </Grid>
                    <Grid item xs={12} className={whatNext.teaserContentContainerItem}>
                        <NavLink to={'/register'} className={whatNext.teaserContentButton}>
                            Devin membru
                        </NavLink>
                    </Grid>
                </Grid>
                </TeaserFrame2>
            </Grid>
        </Grid>
    )
}

export default WhatNext;