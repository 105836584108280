import React from "react";
import {List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import copita_tap from "../../assets/images/kozel_meet/copita_tap.png";
import useListInfoStyles from "../../styles/kozel/listInfo";

interface ListItem {
    text: string
}

interface ListInfoProps {
    items: ListItem[]
}

const ListInfo: React.FC<ListInfoProps> = ({items}) => {
    const listInfoStyles = useListInfoStyles();
    return (
        <List className={listInfoStyles.contentTeaserList}>
            {items.map((item, index) => (
                <ListItem key={index} className={listInfoStyles.contentTeaserListItem}>
                    <ListItemIcon><img src={copita_tap} alt={'copita_tap'}/></ListItemIcon>
                    <ListItemText primary={item.text}/>
                </ListItem>
            ))}
        </List>
    )
}

export default ListInfo;