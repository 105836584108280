import React from "react";
import {Grid} from "@mui/material";
import useTeaserFrame2 from "../styles/teaserFrame2";

interface TeaserFrame2Props {
    title: React.ReactNode,
    titleMarginTop?: string,
    backgroundBoxTitle?: string,
    children: React.ReactNode,
}

const TeaserFrame2: React.FC<TeaserFrame2Props> = ({title, titleMarginTop, backgroundBoxTitle, children}) => {
    const teaserFrame2 = useTeaserFrame2();
    return (
        <Grid container className={teaserFrame2.container}>
            <Grid item xs={12} className={teaserFrame2.containerItem} style={{ marginTop: titleMarginTop }}>
                <div className={teaserFrame2.boxTitle} style={{ background: backgroundBoxTitle,}}>{title}</div>
                <Grid container>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TeaserFrame2;