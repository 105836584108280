import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './stores/store';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { JssProvider, ThemeProvider } from 'react-jss';
import theme from './styles/themes/default';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <JssProvider>
              <MuiThemeProvider theme={theme}>
                  <ThemeProvider theme={theme}>
                      <Provider store={store}>
                        <App />
                      </Provider>
                  </ThemeProvider>
              </MuiThemeProvider>
          </JssProvider>
      </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
