import {createUseStyles} from 'react-jss';
import {Theme} from '../themes/default';
import bgWoodDark from '../../assets/images/bg1.png'

const useFormSectionStyles = createUseStyles((theme: Theme) => {
    return {
        partnerSelectionBox: {
            backgroundColor: theme.palette.background.default,
            justifyContent: 'space-around'
        },
        partnerSelection: {
            justifyContent: 'center',
            textAlign: "center",
            '&.active': {
                backgroundImage: `url(${bgWoodDark})`,
            }
        },
        bgWoodDark: {
            backgroundImage: `url(${bgWoodDark})`,
        },
        titlePromotionBox: {
            textAlign: "center",
            marginTop: theme.spacing(5),
        },
        titlePromotion: {
            fontFamily: 'PoplarKozel, san-serif',
            color: theme.palette.background.default,
            fontSize: 'max(2.5vw,2.5vh)',
            [theme.breakpoints.down('sm')]: {
                fontSize: 'max(4vw,4vh)',
                letterSpacing: '1px',
            }
        },
        subTitlePromotion: {
            marginTop: theme.spacing(3),
            fontFamily: 'PoplarKozel, san-serif',
            color: theme.palette.background.default,
            fontSize: 'max(2vw,2vh)',
            [theme.breakpoints.down('sm')]: {
                fontSize: 'max(3vw,3vh)',
                letterSpacing: '1px',
            }
        },
        prizeStep: {
            margin: `${theme.spacing(5)} max(10vw,10vh)`,
            display: 'flex',
            borderWidth: '5px',
            borderStyle: 'dotted',
            borderColor: theme.palette.secondary.main,
            padding: `${theme.spacing(3)} max(10vh,10vw)`,
            position: "relative",
            [theme.breakpoints.down('sm')]: {
                margin: `${theme.spacing(3)} max(2vw,2vh)`,
                padding: `${theme.spacing(3)} max(2vh,2vw)`,
            }
        },
        prizeStepText: {
            fontFamily: theme.fonts.apex,
            color: theme.palette.secondary.main,
            fontSize: 'max(2vw,2vh)',
            fontWeight: 'bold',
            zIndex: 10,
            [theme.breakpoints.down('sm')]: {
                padding: '5px',
            }
        },
        textRight: {
            textAlign: "right",
        },
        digitImg: {
            height: '100%',
            maxHeight: '75px',
            filter: 'drop-shadow(0px 0px 0px black)',
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'translate(-50%,-50%)',
            [theme.breakpoints.down('sm')]: {
                maxHeight: '50px',
            }
        },
        digitImgRight: {
            top: 0,
            left: 'unset',
            right: 0,
            transform: 'translate(50%,-50%)',
            [theme.breakpoints.down('sm')]: {

            }
        },
        containerFormNcp: {
            justifyContent: 'center',
        },
        itemFormNcp: {
            textAlign: "center",
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            }
        },
        aboutPromotionSection: {
            borderTop: `15px dotted ${theme.palette.secondary.main}`,
            margin: `0 max(10vw,10vh) max(10vw,10vh) max(10vw,10vh)`,
            [theme.breakpoints.down('sm')]: {
                borderTopWidth: '12px',
                margin: `0 max(3vw,3vh) max(4vw,4vh) max(3vw,3vh)`,
            }
        },
        aboutPromotionText: {
            fontFamily: theme.fonts.apex,
            fontWeight: 'lighter',
            fontSize: 'max(1.5vh,1.5vw)',
            letterSpacing: '1px',
            color: theme.palette.secondary.main,
            textAlign: "center",
            marginTop: theme.spacing(3),
            paddingTop: theme.spacing(2),
            textTransform: 'uppercase',
            '& a': {
                color: "inherit",
                '&:hover': {
                  color: theme.palette.primary.light
                },
            }
        },
        step2PennyCard:{
            right: 0,
            position: 'absolute',
            top: 0,
            transform: 'translate(50%, -25%)',
            maxHeight: '150px',
            width: 'auto',
            zIndex: 1,
            [theme.breakpoints.down('md')]: {
                top: '100%',
                right: '0',
                width: 'auto',
                position: 'absolute',
                transform: 'rotate(0) scale(0.75) translate(25%, -75%)',
            },
            [`@media (orientation: landscape)`]: {
                transform: 'translate(30%, -25%)',
            },
            [theme.breakpoints.up('lg')]: {
                maxHeight: '250px',
            }
        },
    };
});

export default useFormSectionStyles;