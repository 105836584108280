import React from "react";
import {Grid, Typography} from "@mui/material";
import useAmrUntold from "../../styles/my_group/amrUntold";
import dayjs from "dayjs";

const AmrUntold: React.FC = () => {
    const amrUntold= useAmrUntold();
    const targetDate = dayjs('2024-08-08');
    const today = dayjs();
    const daysLeft = targetDate.diff(today, 'day');

    return (
        <Grid container className={amrUntold.wrapperContainer}>
            <Grid item xs={12}>
                <Grid container className={amrUntold.container}>
                    <Grid item xs={12} className={amrUntold.containerItem}>
                        <Typography className={amrUntold.textAmr}>AMR</Typography>
                    </Grid>
                    <Grid item xs={12} className={(amrUntold.containerItem)}>
                        <Typography className={amrUntold.textNrDays}>{daysLeft}</Typography>
                        <div className={amrUntold.dottedLineBackground}>&nbsp;</div>
                    </Grid>
                    <Grid item xs={12} className={amrUntold.containerItem}>
                        <Typography className={amrUntold.textDay}>{daysLeft >= 20 && 'de'} Zile</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AmrUntold;