import {createUseStyles} from "react-jss";
import {Theme} from "./themes/default";

const useModalWrapperStyles = createUseStyles((theme: Theme) => {
    return {
        container: {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60vw',
            maxHeight: '98vh',
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.spacing(3),
            overflow: "scroll",
            [theme.breakpoints.down('sm')]: {
                width: '100vw',
            },

        }
    }
});

export default useModalWrapperStyles;