import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

export interface Breed {
    breed: string;
    country: string;
    origin: string;
    coat: string;
    pattern: string;
}

interface BreedsState {
    breeds: Breed[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: BreedsState = {
    breeds: [],
    status: 'idle',
    error: null,
};

export const fetchBreeds = createAsyncThunk('breeds/fetchBreeds', async () => {
    const response = await axios.get('https://catfact.ninja/breeds');
    return response.data.data;
});

const breedsSlice = createSlice({
    name: 'breeds',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBreeds.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBreeds.fulfilled, (state, action: PayloadAction<Breed[]>) => {
                state.status = 'succeeded';
                state.breeds = action.payload;
            })
            .addCase(fetchBreeds.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || 'Failed to fetch breeds';
            });
    },
});

export default breedsSlice.reducer;
