import {createUseStyles} from 'react-jss';
import {Theme} from './themes/default';
import bgImgDark from '../assets/images/bg1.png';
import bgImgLight from '../assets/images/bg2.png';
import history_img_1 from '../assets/images/history_img_1.png';
import history_img_2 from '../assets/images/history_img_2.png';
import history_img_3 from '../assets/images/history_img_3.png';
import history_img_4 from '../assets/images/history_img_4.png';
import history_img_5 from '../assets/images/history_img_5.png';
import history_img_6 from '../assets/images/history_img_6.png';
import history_img_7 from '../assets/images/history_img_7.png';

const useOurTraditionStyles = createUseStyles((theme: Theme) => {
    return {
        section:{
            position: "relative",
        },
        subSectionLeft:{
            backgroundImage: `url(${bgImgLight})`,
            padding: theme.spacing(10),
            backgroundSize: 'cover',
        },
        subSectionRight:{
            backgroundImage: `url(${bgImgDark})`,
            padding: theme.spacing(10),
            backgroundSize: 'cover',
        },
        title:{
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(6vw,6vh)',
            textAlign: 'center',
            padding: theme.spacing(10),
        },
        sectionTitle:{
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(4vw,4vh)',
            textAlign: 'left',
            color: theme.palette.primary.main,
            paddingBottom: theme.spacing(10),
            '&.contrast': {
                color: theme.palette.primary.light,
            }
        },
        sectionSubtitle:{
            fontFamily: theme.fonts.poplar,
            fontSize: 'max(2.5vw,2.5vh)',
            textAlign: 'left',
            color: theme.palette.primary.main,
            paddingBottom: theme.spacing(10),
            '&.contrast': {
                color: theme.palette.primary.light,
            }
        },
        description:{
            fontFamily: theme.fonts.apex,
            fontSize: 'max(2vw,2vh)',
            textAlign: 'left',
            color: theme.palette.primary.main,
            paddingBottom: theme.spacing(10),
            '&.contrast': {
                color: theme.palette.primary.light,
            }
        },
        bgImgOurTradition1:{
            backgroundImage: `url(${history_img_1})`,
            backgroundSize: 'cover',
            minHeight: 'max(30vh,30vw)',
            width: '100%',
        },
        bgImgOurTradition2: {
            backgroundImage: `url(${history_img_2})`,
            backgroundSize: 'cover',
            minHeight: 'max(30vh,30vw)',
            width: '100%',
        },
        bgImgOurTradition3:{
            backgroundImage: `url(${history_img_3})`,
            backgroundSize: 'cover',
            minHeight: 'max(30vh,30vw)',
            width: '100%',
        },
        bgImgOurTradition4:{
            backgroundImage: `url(${history_img_4})`,
            backgroundSize: 'cover',
            minHeight: 'max(30vh,30vw)',
            width: '100%',
        },
        bgImgOurTradition5:{
            backgroundImage: `url(${history_img_5})`,
            backgroundSize: 'cover',
            minHeight: 'max(30vh,30vw)',
            width: '100%',
        },
        bgImgOurTradition6:{
            backgroundImage: `url(${history_img_6})`,
            backgroundSize: 'cover',
            minHeight: 'max(30vh,30vw)',
            width: '100%',
        },
        bgImgOurTradition7:{
            backgroundImage: `url(${history_img_7})`,
            backgroundSize: 'cover',
            minHeight: 'max(30vh,30vw)',
            width: '100%',
        },

    }
});

export default useOurTraditionStyles;